import React from "react";
import styled, { css } from "styled-components";
import { darken } from "polished";
import { Tooltip } from "react-tippy";
import { Spinner } from "../";

const sharedStyles = css`
  border-radius: 0.25rem;
  padding: 0 1.5rem;
  line-height: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  min-width: 100px;
  margin-left: 0.5rem;
  transition: background 0.25s;
`;

const primaryStyles = css`
  background: ${(props) => props.theme.colorPrimary};
  color: white;

  &:hover {
    background: ${(props) => darken(0.05, props.theme.colorPrimary)};
  }
`;

const defaultStyles = css`
  background: ${(props) => props.theme.colorLightGrey};
  color: ${(props) => props.theme.colorText};
  font-weight: 500;

  &:hover {
    background: ${(props) => darken(0.025, props.theme.colorLightGrey)};
  }
`;

const disabledStyles = css`
  background: ${(props) =>
    props.isLoading ? "inherit" : props.theme.colorDisabledBackground};
  color: ${(props) => props.theme.colorDisabledText};
  cursor: not-allowed;
`;

const StyledButton = styled.button`
  ${sharedStyles};
  ${(p) => p.isPrimary && primaryStyles};
  ${(p) => !p.isPrimary && defaultStyles};

  &:disabled {
    ${disabledStyles};
  }
`;

const BlockButton = ({
  isLoading,
  children,
  disabled,
  tooltip,
  isPrimary,
  ...rest
}) => (
  <Tooltip
    disabled={!tooltip}
    title={tooltip}
    position="top"
    delay={300}
    distance={20}
  >
    <StyledButton
      {...rest}
      isPrimary={isPrimary}
      disabled={disabled || isLoading}
    >
      {!isLoading && children}
      {isLoading && <Spinner forDark lineWidth={4} />}
    </StyledButton>
  </Tooltip>
);

export default BlockButton;
