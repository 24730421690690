import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getDashboard, clearDashboard } from "./actions";
import { FadeIn } from "components/shared";
import DashboardStatCard from "./DashboardStatCard";
import DashboardBarCard from "./DashboardBarCard";
import DashboardLineCard from "./DashboardLineCard";
import FixtureTable from "../fixtureTable/FixtureTable";
import DashBoardPlaceHolder  from "./DashBoardPlaceHolder"
const Dashboard = ({ getDashboard, clearDashboard, dashboard }) => {
  const { stats, bars, lineChart } = dashboard.data;

  useEffect(() => {
    getDashboard();
    return () => clearDashboard();
  }, [getDashboard, clearDashboard]);

  return stats ? (
    <div>
      <FadeIn>
        <div className="row">
          <div className="col-sm-12 col-md-9">
            <div className="row">
              {stats &&
                stats.map((card) => (
                  <div key={card.title} className="col-6 col-sm-4">
                    <DashboardStatCard
                      cardValue={card.value}
                      cardTitle={card.title}
                      cardOptions={card.options}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            {bars &&
              bars.map((powerBar) => (
                <DashboardBarCard
                  bars={powerBar.bars}
                  cardTitle={powerBar.title}
                />
              ))}
          </div>
        </div>
      </FadeIn>
      <FadeIn isSecond>
        <DashboardLineCard data={lineChart} cardTitle="Entries Per Contest" />
      </FadeIn>
      <FadeIn isThird>
        <FixtureTable
          fixtureCount={5}
          cardTitle="Upcoming Fixtures"
          linkTo="/fixtures"
          linkToText="View All"
        />
      </FadeIn>
    </div>
  ) : <DashBoardPlaceHolder/>; //put here placeholder component  while dashboard finishes loading.
};

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

const mapActionsToProps = {
  getDashboard,
  clearDashboard,
};

export default connect(mapStateToProps, mapActionsToProps)(Dashboard);
