import React from "react";
import NumberFormat from "react-number-format";
const PenceToPounds = ({ children }) => (
    <NumberFormat
        displayType="text"
        value={children / 100}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator=","
    />
);
export default PenceToPounds;