import React, { useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  FadeIn,
  LinkButton,
  TitleStack,
  IconButton,
  Chip,
  SkeletonTable,
  FormattedDate,
  FormattedTime,
  LabelText,
  TableSortButton,
} from "components/shared";
import { Table, Thead, Tbody, Tr, Td, Th } from "components/shared/table/Table";
import { useSorting } from "components/hooks";
import { getFixtures, clearFixtures } from "./actions";

const StyledVs = styled.span`
  margin: 0 1.75rem;
  color: ${(props) => props.theme.colorLabel};
`;

const StyledIconSpacer = styled.span`
  display: inline-block;
  width: 10px;
`;

const FixtureTable = (props) => {
  const { data } = props.fixtureTable;
  const {
    getFixtures,
    clearFixtures,
    cardTitle,
    linkTo,
    linkToText,
    fixtureCount,
    teamName,
  } = props;

  const [sortField, sortDirection, sortButtonClicked, sortedItems] = useSorting(
    "matchDay",
    "asc",
    data
  );

  useEffect(() => {
    getFixtures();
    return () => clearFixtures();
  }, [getFixtures, clearFixtures]);

  const isTenantTeam = (team) => teamName === team;

  return !sortedItems ? (
    <SkeletonTable />
  ) : (
    <Card linkTo={linkTo} linkToText={linkToText}>
      <CardHeader cardTitle={cardTitle} />
      <FadeIn>
        <Table cellVerticalAlign="top">
          <Thead isSortable>
            <Tr>
              <Th style={{ width: "50px" }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="matchDay"
                  sortDirection={sortDirection}
                  isSorted={sortField === "matchDay"}
                >
                  Day
                </TableSortButton>
              </Th>
              <Th style={{ width: "120px", minWidth: "120px" }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="kickoff"
                  sortDirection={sortDirection}
                  isSorted={sortField === "kickoff"}
                >
                  Kickoff
                </TableSortButton>
              </Th>
              <Th style={{ width: "160px", minWidth: "160px" }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="homeTeamName"
                  sortDirection={sortDirection}
                  isSorted={sortField === "homeTeamName"}
                >
                  Home Team
                </TableSortButton>
              </Th>
              <Th style={{ width: "160px", minWidth: "160px" }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="awayTeamName"
                  sortDirection={sortDirection}
                  isSorted={sortField === "awayTeamName"}
                >
                  Away Team
                </TableSortButton>
              </Th>
              <Th
                style={{
                  width: "100px",
                  minWidth: "100px",
                  textAlign: "right",
                }}
              >
                Contest
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedItems.map((fixture, index) =>
              (fixtureCount && index < fixtureCount) || !fixtureCount ? (
                <Tr key={fixture.matchDay}>
                  <Td>{fixture.matchDay}</Td>
                  <Td>
                    <TitleStack
                      title={<FormattedDate>{fixture.kickoff}</FormattedDate>}
                      description={<span>
                        <FormattedTime>{fixture.kickoff}</FormattedTime> gmt</span>
                      }
                    />
                  </Td>
                  <Td>
                    {!isTenantTeam(fixture.homeTeam.name) ? (
                      <LabelText>{fixture.homeTeam.name}</LabelText>
                    ) : (
                      fixture.homeTeam.name
                    )}
                  </Td>
                  <Td>
                    {!isTenantTeam(fixture.awayTeam.name) ? (
                      <LabelText>{fixture.awayTeam.name}</LabelText>
                    ) : (
                      fixture.awayTeam.name
                    )}
                  </Td>
                  <Td style={{ textAlign: "right" }}>
                    {fixture.pickems.length === 0 && (
                      <IconButton
                        tooltip="Create"
                        to={`/fixtures/${fixture.id}/${fixture.homeTeam.name}/${fixture.awayTeam.name}/create-contest`}
                      >
                        add
                      </IconButton>
                    )}
                    {fixture.pickems.length > 0 && (
                      <IconButton
                        tooltip="Edit"
                        to={`/fixtures/${fixture.id}/${fixture.pickems[0].id}`}
                      >
                        edit
                      </IconButton>
                    )}
                  </Td>
                </Tr>
              ) : null
            )}
          </Tbody>
        </Table>
      </FadeIn>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  fixtureTable: state.fixtureTable,
  teamName: state.auth.teamName,
});

const mapActionsToProps = {
  getFixtures,
  clearFixtures,
};

export default connect(mapStateToProps, mapActionsToProps)(FixtureTable);
