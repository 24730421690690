import React from "react";
import styled from "styled-components";
import NavbarMenuButton from "./NavbarMenuButton";
import navItems from "../navItems";

const StyledNavbarMenu = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const NavbarMenu = () => (
  <StyledNavbarMenu>
    {navItems.map((i) => (
      <NavbarMenuButton
        key={i.path}
        path={i.path}
        text={i.text}
        icon={i.icon}
      />
    ))}
  </StyledNavbarMenu>
);

export default NavbarMenu;
