import axios from "axios";
import { handleError } from "utils";

export const GET_LEADERBOARD = "leaderboard:GET_LEADERBOARD";

export const getLeaderboard = () => (dispatch) => {
  axios
    .get(`/leaderboard/season?type=football&season=2020&cache=false`, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
    .then((res) => {
      dispatch({
        type: GET_LEADERBOARD,
        payload: res.data.data.users,
      });
    })
    .catch(handleError);
};
