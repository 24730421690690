import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const StyledSpinner = styled.span`
  display: inline-block;
  border-width: ${(props) => props.lineWidth}px;
  border-style: solid;
  vertical-align: middle;

  border-color: ${(props) =>
    props.forDark
      ? props.theme.colorSpinnerBackgroundForDark
      : props.theme.colorSpinnerBackground};

  border-top-color: ${(props) =>
    props.forDark ? "white" : props.theme.colorPrimary};

  width: ${(props) => Math.floor(props.lineWidth * 5)}px;
  height: ${(props) => Math.floor(props.lineWidth * 5)}px;
  border-radius: 50%;
  animation: spin 1.5s infinite linear;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = ({ className, lineWidth, forDark }) => (
  <StyledSpinner
    className={className}
    lineWidth={lineWidth}
    forDark={forDark}
  />
);

Spinner.defaultProps = {
  lineWidth: 7,
  forDark: false,
};

Spinner.propTypes = {
  lineWidth: PropTypes.number,
  forDark: PropTypes.bool,
};

export default Spinner;
