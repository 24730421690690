import React from "react";
import styled from "styled-components";

import { Container } from "components/shared";

import NavbarLogo from "./NavbarLogo";
import NavbarMenu from "./NavbarMenu";

const StyledNavbar = styled.div`
  padding: 1.25rem 0;
  background: white;
`;

const Navbar = () => (
  <StyledNavbar>
    <Container>
      <NavbarLogo />
      <NavbarMenu />
    </Container>
  </StyledNavbar>
);

export default Navbar;
