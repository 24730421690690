import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { MatIcon } from "../";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0.75rem 0;
  font-weight: 500;
  background: transparent;
  color: ${(props) => props.theme.colorPrimary};
`;

const StyledIcon = styled(MatIcon)`
  margin-right: 0.325rem;
`;

const DefaultButton = (props) => (
  <StyledButton {...props}>
    {props.icon && <StyledIcon>{props.icon}</StyledIcon>}
    <span>{props.children}</span>
  </StyledButton>
);

DefaultButton.propTypes = {
  icon: PropTypes.string,
};

export default DefaultButton;
