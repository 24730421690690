import React from "react";
import styled from "styled-components";
import { MatIcon } from "components/shared";
import TopbarMenu from "./TopbarMenu";

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    flex: 0 1 170px;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-weight: 500;
  color: inherit;
`;

const StyledButtonText = styled.span`
  margin: 0 0.325rem 0 0.5rem;
  display: inline-block;
  white-space: nowrap;
`;

const TopbarButton = ({ logout, handleClick, dropdownOpen, userFullName }) => {
  return (
    <StyledWrapper>
      <StyledButton onClick={() => handleClick(true)}>
        <StyledButtonText>{userFullName}</StyledButtonText>
        <MatIcon size="20px">expand_more</MatIcon>
      </StyledButton>
      <TopbarMenu logout={logout} dropdownOpen={dropdownOpen} />
    </StyledWrapper>
  );
};

export default TopbarButton;
