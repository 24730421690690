import React from "react";
import styled from "styled-components";

const StyledIcon = styled.span`
  font-size: ${(props) => props.size || "24px"};
  margin: ${(props) => props.margin || "0"};
`;

const MatIcon = (props) => (
  <StyledIcon
    style={props.style}
    size={props.size}
    margin={props.margin}
    className={`${props.className} material-icons-outlined`}
  >
    {props.children}
  </StyledIcon>
);

export default MatIcon;
