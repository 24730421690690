export default [
  {
    path: "/dashboard",
    text: "Dashboard",
    icon: "analytics",
  },
  {
    path: "/team",
    text: "Team",
    icon: "security",
  },
  {
    path: "/fixtures",
    text: "Fixtures",
    icon: "event_note",
  },
  {
    path: "/questions",
    text: "Questions",
    icon: "chat",
  },
  {
    path: "/leaderboard",
    text: "Leaderboard",
    icon: "emoji_events",
  },
  {
    path: "/users",
    text: "Users",
    icon: "group",
  },
];
