import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import Navbar from "./navbar/Navbar";
import Topbar from "./topbar/Topbar";

const floatingMenuIn = keyframes`
  0% {
    transform: translateY(-152px);
  }
  100% {
    transform: translateY(0);
  }
`;

const scrolledStyles = css`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px -4px 10px #c4d1e4;
  animation: ${(props) => (props.isScrolled === true ? floatingMenuIn : "")}
    0.5s;
`;

const StyledWrapper = styled.div`
  ${(props) => scrolledStyles};
`;

const heightToAppear = 150;
const heightToDisappear = 0;

const FloatingHeader = () => {
  const [isScrolled, setIsScrolled] = useState(undefined);

  const listener = (e) => {
    const { scrollY } = window;

    if (!isScrolled && scrollY > heightToAppear) {
      setIsScrolled(true);
    }

    if (isScrolled && scrollY <= heightToDisappear) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [listener]);

  return (
    <>
      <Topbar />
      <Navbar />
      {isScrolled && (
        <StyledWrapper isScrolled={isScrolled}>
          <Topbar isScrolled />
        </StyledWrapper>
      )}
    </>
  );
};

export default FloatingHeader;
