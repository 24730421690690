import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, CardFooter, BlockButton } from "components/shared";
import { Table, Thead, Tbody, Tr, Td, Th } from "components/shared/table/Table";
import { setActiveModal } from "components/layout/overlay/actions";

const ContestPublishModal = ({ isOpen, onConfirm }) => {
  const activeContest = useSelector((state) => state.contest.activeContest);
  const dispatch = useDispatch();

  return (
    <Modal
      idealWidth="700px"
      title={`Publish - ${activeContest && activeContest.name}`}
      isOpen={isOpen}
    >
      <Table>
        <Thead>
          <Tr>
            <Th style={{ width: "36px" }}>#</Th>
            <Th>Question</Th>
            <Th style={{ width: "80px" }}>Points</Th>
          </Tr>
        </Thead>
        <Tbody>
          {activeContest &&
            activeContest.questions &&
            activeContest.questions.map((q, i) => (
              <Tr>
                <Td>{i + 1}</Td>
                <Td>{q.label}</Td>
                <Td>{q.availablePoints}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <CardFooter borderTop justifyContent="flex-end">
        <BlockButton onClick={() => dispatch(setActiveModal(false))}>
          Cancel
        </BlockButton>
        <BlockButton
          isPrimary
          onClick={() => {
            onConfirm();
            dispatch(setActiveModal(false));
          }}
        >
          Publish
        </BlockButton>
      </CardFooter>
    </Modal>
  );
};

export default ContestPublishModal;
