import { useState } from "react";

const useSorting = (initialSortField, initialSortDirection, items) => {
  const [sortField, setSortField] = useState(initialSortField);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);

  const sortButtonClicked = (sortField, sortDirection) => {
    setSortField(sortField);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const sortedItems = !items
    ? undefined
    : items.sort((a, b) => {
        const propA = a[sortField].toString().toLowerCase();
        const propB = b[sortField].toString().toLowerCase();

        if (sortDirection === "asc") {
          if (propA < propB) {
            return -1;
          }
          if (propA > propB) {
            return 1;
          }
        } else if (sortDirection === "desc") {
          if (propA > propB) {
            return -1;
          }
          if (propA < propB) {
            return 1;
          }
        }

        return 0;
      });

  return [sortField, sortDirection, sortButtonClicked, sortedItems];
};

export default useSorting;
