import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { MatIcon } from "../../../shared";

const StyledIcon = styled(MatIcon)`
  font-size: 24px;
  margin-bottom: 0.325rem;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    font-size: 32px;
  }
`;

const StyledText = styled.span`
  font-weight: 500;
  font-size: 0.75rem;
  display: none;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    font-size: 1rem;
  }
`;

const StyledNavLink = styled(NavLink)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colorLabel};

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    border-right: 1px solid ${(props) => props.theme.colorBorder};
  }

  &:last-child {
    border-right: 0;
  }

  &.active ${StyledIcon} {
    color: ${(props) => props.theme.colorPrimary};
  }

  &.active ${StyledText} {
    color: ${(props) => props.theme.colorPrimary};
  }
`;

const NavbarMenuButton = ({ path, icon, text }) => (
  <StyledNavLink to={path} exact={path === "/"}>
    <StyledIcon>{icon}</StyledIcon>
    <StyledText>{text}</StyledText>
  </StyledNavLink>
);

export default NavbarMenuButton;
