import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import { Spinner } from "../";

const StyledButton = styled.button`
  border-radius: 0.25rem;
  padding: 0 1.5rem;
  line-height: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  min-width: 100px;
  margin-left: 0.5rem;
  background: ${(props) => props.theme.colorPrimary};
  color: white;
  transition: background 0.25s;

  &:hover {
    background: ${(props) => darken(0.05, props.theme.colorPrimary)};
  }

  &:disabled {
    background: ${(props) =>
      props.isLoading
        ? darken(0.15, props.theme.colorPrimary)
        : props.theme.colorDisabledBackground};
    color: ${(props) => props.theme.colorDisabledText};
    cursor: default;
  }
`;

const PrimaryButton = (props) => {
  const { isLoading, children, disabled } = props;

  return (
    <StyledButton {...props} disabled={disabled || isLoading}>
      {!isLoading && children}
      {isLoading && <Spinner forDark lineWidth={4} />}
    </StyledButton>
  );
};

export default PrimaryButton;
