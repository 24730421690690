import axios from "axios";
import { handleError } from "../../../utils";
import { loadFromLocalStorage } from "../auth/actions";

export const GET_QUESTIONS = "questions:GET_QUESTIONS";
export const CLEAR_QUESTIONS = "questions:CLEAR_QUESTIONS";

export const getQuestions = () => (dispatch) => {
  axios
    .get(`/question-templates`)
    .then((res) => {
      for (let item of res.data.data.questionTemplates) {
        item.formattedTitle = item.title
          .replace("<Period>", "(First/Second Half)")
          .replace("<Number>", "(Number)")
          .replace("<Player Name>", "(Player)")
          .replace("<Current tenant club>", localStorage.getItem("teamName"))
          .replace(/[<]/g, "")
          .replace(/[>]/g, "");

        item.formattedOptions = item.answer_options
          .replace("COUNTABLE", "Multiple choice between 0 and more than 6")
          .replace("YES/NO", "Yes or No")
          .replace("FOOTBALL_MATCH_MINUTES", "15 minute interval options");
      }

      dispatch({
        type: GET_QUESTIONS,
        payload: res.data.data,
      });
    })
    .catch(handleError);
};

export function clearQuestions() {
  return {
    type: CLEAR_QUESTIONS,
    payload: undefined,
  };
}

// export const deleteQuestion = (questionId) => (dispatch) => {
//   axios.delete(`/api/questions/${questionId}`).then((res) => {
//     dispatch(getQuestions());
//   });
// };
// export const addQuestion = (question) => (dispatch) => {
//   axios.post(`/api/questions/`, question).then((res) => {
//     dispatch(getQuestions());
//   });
// };
