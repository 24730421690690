import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  padding-top: 1rem;
  margin-bottom: 1rem;
`;

const TextboxLabel = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  transform: ${(props) =>
    props.notEmpty
      ? "translate(0, 6px) scale(0.75, 0.75);"
      : "translate(0, 36px)"};
  transform-origin: top left;
  font-weight: 500;
  color: ${(props) => props.theme.colorLabel};
  transition: transform 0.25s;
`;

const Textbox = styled.input`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 56px;
  line-height: 56px;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  border-bottom: 2px solid ${(props) =>
    props.isRequired && !props.isValid
      ? props.theme.colorDanger
      : props.theme.colorBorder};
  background: transparent;
  color: ${(props) => props.theme.colorText};

  &:disabled {
    user-select: none;
    color: ${(props) =>
      props.isReadonly ? props.theme.colorText : props.theme.colorDisabledText};
  }

  &:focus {
    outline: none;
    border-color: ${(props) =>
      !props.isValid ? props.theme.colorDanger : props.theme.colorPrimary};
  }

  &:focus ~ span {
    color: ${(props) =>
      !props.isValid ? props.theme.colorDanger : props.theme.colorPrimary};
  }

  &:focus ~ div {
    transform: translate(0, 6px) scale(0.75, 0.75);
    color: ${(props) =>
      !props.isValid ? props.theme.colorDanger : props.theme.colorPrimary};};
  }
`;

const MatTextbox = (props) => {
  const {
    value,
    type,
    name,
    label,
    onTextboxChanged,
    autoFocus,
    isRequired,
    isReadonly,
    disabled,
  } = props;

  return (
    <Wrapper>
      <Textbox
        isReadonly={isReadonly}
        disabled={isReadonly || disabled}
        autoFocus={autoFocus}
        name={name}
        type={type}
        value={value}
        onChange={onTextboxChanged}
        isRequired={isRequired}
        isValid={!isRequired || (value && value.toString().length > 0)}
      />
      <TextboxLabel notEmpty={value && value.toString().length > 0}>
        {label}
        {isRequired && <span> *</span>}
      </TextboxLabel>
    </Wrapper>
  );
};

export default MatTextbox;
