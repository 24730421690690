import { GET_LEADERBOARD } from "./actions";

const initialState = {
    data: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case GET_LEADERBOARD:
            return {
                ...state,
                data: payload,
            };
        default:
            return state;
    }
}