import { GET_DASHBOARD, CLEAR_DASHBOARD } from "./actions";

const initialState = {
  data: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_DASHBOARD:
      return {
        ...state,
        data: payload,
      };
    case CLEAR_DASHBOARD:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
}
