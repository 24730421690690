import axios from "axios";
import { toast } from "react-toastify";
import history from "router/history";
import { handleError } from "utils";

export const SET_OVERLAY_ISOPEN = "modal:SET_OVERLAY_ISOPEN";
export const SET_ACTIVE_MODAL = "modal:SET_ACTIVE_MODAL";

export function setOverlayIsOpen(isOpen) {
  return {
    type: SET_OVERLAY_ISOPEN,
    payload: isOpen,
  };
}

export function setActiveModal(activeModal) {
  return {
    type: SET_ACTIVE_MODAL,
    payload: activeModal,
  };
}
