import React from "react";
import { useDispatch } from "react-redux";
import styled, { keyframes } from "styled-components";
import { CardHeader, CardHeaderTitle, CardFooter, IconButton } from "../";
import { setActiveModal } from "components/layout/overlay/actions";

const wrapperIn = keyframes`
  0% { transform: translateY(20px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
`;

const wrapperOut = keyframes`
  0% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(20px); opacity: 0; }
`;

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 11;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition-property: visibility, opacity;
  transition-duration: 0.35s;

  @media (min-width: ${(props) => props.theme.screenWidthSm}) {
    width: ${(props) => props.idealWidth || "300px"};
  }
`;

const StyledModal = styled.div`
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;

  animation: ${(props) =>
      props.isOpen === true
        ? wrapperIn
        : props.isOpen === false
        ? wrapperOut
        : null}
    0.35s;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledContent = styled.div`
  max-height: 80vh;
  overflow: auto;
`;

const Modal = ({ children, title, idealWidth, isOpen }) => {
  const dispatch = useDispatch();

  return (
    <StyledWrapper isOpen={isOpen} idealWidth={idealWidth}>
      <StyledModal isOpen={isOpen}>
        <CardHeader borderBottom>
          <CardHeaderTitle>{title}</CardHeaderTitle>
          <IconButton
            onClick={() => {
              dispatch(setActiveModal(undefined));
            }}
          >
            close
          </IconButton>
        </CardHeader>
        <StyledContent>{children}</StyledContent>
      </StyledModal>
    </StyledWrapper>
  );
};

export default Modal;
