import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getLeaderboard } from "./actions";
import {
  Card,
  CardHeader,
  LinkButton,
  FadeIn,
  SkeletonTable,
} from "components/shared";
import { Table, Thead, Tbody, Tr, Td, Th } from "components/shared/table/Table";

const Leaderboard = ({ leaderboard, getLeaderboard }) => {
  useEffect(() => {
    getLeaderboard();
  }, [getLeaderboard]);

  const { data } = leaderboard;

  return data ? (
    <Card>
      <FadeIn>
        <CardHeader cardTitle="Leaderboard" />
        <Table>
          <Thead>
            <Tr>
              <Th>Place</Th>
              <Th>Username</Th>
              <Th>Points</Th>
              <Th>Winnings</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              data.map((leaderboard) => (
                <Tr>
                  <Td>{leaderboard.rank}</Td>
                  <Td>{leaderboard.username}</Td>
                  <Td>{leaderboard.points}</Td>
                  <Td>£{leaderboard.winnings}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </FadeIn>
    </Card>
  ) : (
    <SkeletonTable items={1} />
  );
};

const mapStateToProps = (state) => ({
  leaderboard: state.leaderboard,
});

const mapActionsToProps = {
  getLeaderboard,
};

export default connect(mapStateToProps, mapActionsToProps)(Leaderboard);
