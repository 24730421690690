import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardFooter,
  IconButton,
  DefaultButton,
  FadeIn,
  MatIcon,
  MatTextbox,
  Skeleton,
  PrimaryButton,
  SecondaryButton,
  LinkButton,
  Chip,
  FormRow,
  FormRowLabel,
  FormInput,
  FormDropdown,
  BlockButton,
} from "components/shared";
import {
  setFormField,
  clearContest,
  createContest,
  updateContest,
  getContest,
} from "./actions";
import { setActiveModal } from "components/layout/overlay/actions";

import ContestPublishModal from "./ContestPublishModal";

const ContestEdit = (props) => {
  const {
    activeContest,
    isSavingContest,
    setFormField,
    createContest,
    clearContest,
    updateContest,
    getContest,
    statusList,
  } = props;
  const { fixtureId, contestId, homeTeam, awayTeam } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.overlay.activeModal);

  const statusOptions =
    statusList &&
    statusList.map((o) => ({
      text: o,
      key: o,
      label: o,
      value: o,
    }));

  const isEdit = contestId !== "create-contest";

  useEffect(() => {
    if (contestId !== "create-contest") {
      getContest(contestId);
    } else {
      setFormField(
        "name",
        `${decodeURIComponent(homeTeam)} vs ${decodeURIComponent(awayTeam)}`,
        "text"
      );
    }
    return () => clearContest();
  }, [getContest, clearContest, contestId, setFormField]);

  const save = () => {
    if (contestId === "create-contest") {
      createContest(fixtureId, {
        name: activeContest.name,
        matchId: fixtureId,
        stake: 0,
        prizeFund: 0,
        status: "DRAFT",
      });
    } else {
      updateContest({
        id: contestId,
        name: activeContest.name,
        status: activeContest.status,
        matchId: fixtureId,
        stake: activeContest.stake,
        prizeFund: activeContest.prizeFund,
      });
    }
  };

  const publish = () => {
    dispatch(setActiveModal("publish-contest"));
  };

  const publishConfirm = () => {
    updateContest(
      {
        id: contestId,
        name: activeContest.name,
        matchId: fixtureId,
        stake: activeContest.stake,
        prizeFund: activeContest.prizeFund,
      },
      true
    );
  };

  const cancel = () => history.push(`/fixtures`);

  const handleFormChanged = (e) => {
    setFormField(e.target.name, e.target.value, e.target.type);
  };

  const isApproved = activeContest && activeContest.status === "APPROVED";

  const saveButtonDisabled =
    !activeContest ||
    !activeContest.name ||
    (activeContest.name && activeContest.name.length === 0);

  const publishButtonDisabled =
    activeContest &&
    activeContest &&
    activeContest.questions &&
    activeContest.questions.length < 1;

  return (
    <>
      <ContestPublishModal
        isOpen={activeModal === "publish-contest"}
        onConfirm={publishConfirm}
      />

      {(activeContest === undefined || activeContest.name === undefined) &&
      contestId !== "create-contest" ? (
        <Card>
          <Skeleton display="block" margin="0.875rem 0 2.75rem" />
          <Skeleton display="block" skeletonWidth="600px" margin="0 0 3rem" />
          <Skeleton display="block" skeletonWidth="400px" margin="0 0 2rem" />
          <Skeleton display="block" skeletonWidth="200px" margin="0 0 2rem" />
          <Skeleton display="block" skeletonWidth="400px" margin="0 0 2rem" />
          <Skeleton display="block" skeletonWidth="200px" margin="0 0 2rem" />
          <Skeleton display="block" skeletonWidth="400px" margin="0 0 2rem" />
          <Skeleton display="block" skeletonWidth="200px" margin="0 0 2rem" />
          <Skeleton display="block" skeletonWidth="400px" margin="0 0 2rem" />
        </Card>
      ) : (
        <Card>
          <CardHeader borderBottom>
            <CardHeaderTitle>
              {isEdit ? "Edit" : "Create"} Contest
            </CardHeaderTitle>
            {isEdit && <Chip tooltip="Status">{activeContest.status}</Chip>}
          </CardHeader>
          <FadeIn>
            <FormRow>
              <MatTextbox
                name="name"
                autoFocus={!isEdit}
                isRequired
                label="Contest Name"
                value={activeContest && activeContest.name}
                onTextboxChanged={handleFormChanged}
              />
            </FormRow>
            {isEdit && (
              <>
                {/* <FormRow>
                   <FormDropdown
                     name="status"
                     value={activeContest && activeContest.status}
                     onChange={handleFormChanged}
                     label="Status"
                     options={statusOptions}
                   />
                 </FormRow> */}
                <div className="row">
                  <div className="col-6">
                    <FormRow>
                      <FormRowLabel>Stake</FormRowLabel>
                      <FormInput
                        isReadOnly
                        prefix="£"
                        name="stake"
                        value={activeContest && activeContest.stake}
                        onChange={handleFormChanged}
                        type="number"
                      />
                    </FormRow>
                  </div>
                  <div className="col-6">
                    <FormRow>
                      <FormRowLabel>Prize Pool</FormRowLabel>
                      <FormInput
                        isReadOnly
                        prefix="£"
                        name="prizeFund"
                        value={activeContest && activeContest.prizeFund / 100}
                        onChange={handleFormChanged}
                        type="number"
                      />
                    </FormRow>
                  </div>
                </div>

                <FormRow>
                  <FormRowLabel>Max Bets Per User</FormRowLabel>
                  <FormInput
                    isReadOnly
                    name="maxBetsPerUser"
                    value={activeContest && activeContest.maxBetsPerUser}
                    onChange={handleFormChanged}
                    type="number"
                  />
                </FormRow>
              </>
            )}
          </FadeIn>
          <CardFooter justifyContent="flex-end">
            {isEdit && (
              <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                <LinkButton
                  to={`/fixtures/${fixtureId}/${contestId}/questions`}
                >
                  <MatIcon margin="0 0.25rem 0 0" size="16px">
                    control_point
                  </MatIcon>
                  Create/Edit Questions
                </LinkButton>
              </div>
            )}
            <div>
              <BlockButton onClick={cancel}>Back</BlockButton>
              {isEdit && !isApproved && (
                <BlockButton
                  tooltip={
                    publishButtonDisabled && "You must add at least 1 question"
                  }
                  disabled={publishButtonDisabled}
                  style={{ marginLeft: "0.5rem" }}
                  onClick={publish}
                >
                  Publish
                </BlockButton>
              )}
              <BlockButton
                isPrimary
                onClick={save}
                isLoading={isSavingContest}
                disabled={saveButtonDisabled}
                tooltip={saveButtonDisabled && "Create Contest"}
              >
                Save
              </BlockButton>
            </div>
          </CardFooter>
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  activeContest: state.contest.activeContest,
  statusList: state.contest.statusList,
  isSavingContest: state.contest.isSavingContest,
});

const mapActionsToProps = {
  setFormField,
  createContest,
  clearContest,
  updateContest,
  getContest,
};

export default connect(mapStateToProps, mapActionsToProps)(ContestEdit);
