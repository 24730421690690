import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props) => props.theme.colorPrimary};
`;

const LinkButton = (props) => (
  <StyledLink to={props.to}>{props.children}</StyledLink>
);

export default LinkButton;
