import React from "react";
import styled from "styled-components";
import { darken } from "polished";

const StyledButton = styled.button`
  border-radius: 0.25rem;
  padding: 0 1.5rem;
  line-height: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  min-width: 100px;
  font-weight: 500;
  background: ${(props) => props.theme.colorLightGrey};
  color: ${(props) => props.theme.colorText};
  transition: background 0.25s;

  &:hover {
    background: ${(props) => darken(0.025, props.theme.colorLightGrey)};
  }
`;

const SecondaryButton = (props) => (
  <StyledButton {...props}>{props.children}</StyledButton>
);

export default SecondaryButton;
