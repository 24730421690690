import React from "react";
import styled from "styled-components";
import TopbarMenuButton from "./TopbarMenuButton";

const StyledMenu = styled.div`
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 0;
  width: 180px;
  padding: 0.75rem 1.25rem;
  border-radius: 0.5rem;
  background: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.08);
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transform: translateY(${(props) => (props.isOpen ? 0 : "10px")});
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition-property: opacity, visibility, transform;
  transition-duration: 0.35s;
`;

const TopbarMenu = ({ logout, dropdownOpen }) => {
  return (
    <StyledMenu onClick={(e) => e.stopPropagation()} isOpen={dropdownOpen}>
      <TopbarMenuButton
        icon="exit_to_app"
        text="Sign Out"
        onClick={logout}
      ></TopbarMenuButton>
    </StyledMenu>
  );
};

export default TopbarMenu;
