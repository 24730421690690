import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { rgba } from "polished";
import navItems from "../navItems";
import { MatIcon } from "components/shared";

const StyledWrapper = styled.div`
  display: none;

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledIcon = styled(MatIcon)`
  font-size: 18px;
  margin-right: 0.325rem;
`;

const StyledText = styled.span`
  font-size: 0.75rem;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  margin: 0 0.75rem;
  color: ${(p) => rgba(p.colorTeamText || "white", 0.5)};

  &.active ${StyledIcon} {
    color: ${(props) => props.colorTeamText};
  }

  &.active ${StyledText} {
    color: ${(props) => props.colorTeamText};
  }
`;

const TopbarNav = ({ colorTeamText }) => (
  <StyledWrapper>
    {navItems.map((i) => (
      <StyledNavLink key={i.path} to={i.path} colorTeamText={colorTeamText}>
        <StyledIcon>{i.icon}</StyledIcon>
        <StyledText>{i.text}</StyledText>
      </StyledNavLink>
    ))}
  </StyledWrapper>
);

export default TopbarNav;
