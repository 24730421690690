import React from "react";
import styled from "styled-components";
import {ReactComponent as LoopLogo} from 'LoopLogo2.svg'

const StyledLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
`;

const StyledLogoTitle = styled.div`
 /*  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.325rem;
  letter-spacing: 2px; 
  ALL THIS IS UNUSED NOW, KEEPING IT AS REFERENCE*/
  .looplogo{
height:60px;

.st0{fill: #4684E2 !important;

  
}
  }
`;

const LoginLogo = () => (
  <StyledLogo>
    <StyledLogoTitle><LoopLogo className="looplogo"/></StyledLogoTitle>
  </StyledLogo>
);

export default LoginLogo;
