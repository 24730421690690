import React from 'react'
import styled from 'styled-components'
import Spinner from '../../shared/spinner/Spinner'


const DashBoardPlaceHolder = () => {
    return (
        <div>
            <ButtonHolder> Generating Dashboard...<Spinner  lineWidth={4}/></ButtonHolder> 
        </div>
    )
}

export default DashBoardPlaceHolder
const ButtonHolder = styled.div`
  border-radius: 5px;
background-color:   white;
height: 30px;
width:250px;
margin: 0 auto;
display: flex;
align-items: center;
justify-content: center;
`

