import React from "react";
import styled from "styled-components";
import { rgba } from "polished";

const StyledOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba("white", 0.625)};
`;

const StyledBanner = styled.div`
  position: absolute;
  top: 30px;
  right: -50px;
  width: 200px;
  text-align: center;
  background: ${(props) => props.theme.colorPrimary};
  color: white;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 1rem;
  transform: rotate(45deg);
  user-select: none;
  pointer-events: none;
`;

const StyledBannerAlt = styled.div`
  position: absolute;
  top: 30px;
  right: 2rem;
  border-radius: 0.325rem;
  text-align: center;
  background: ${(props) => props.theme.colorPrimary};
  color: white;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 1rem;
`;

const CardComingSoon = (props) => (
  <StyledOverlay>
    <StyledBanner>Coming Soon</StyledBanner>
  </StyledOverlay>
);

export default CardComingSoon;
