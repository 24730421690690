import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledTitle = styled.div`
  margin-bottom: ${(props) => (props.hasDescription ? "0.325rem" : 0)};
  font-size: 0.875rem;
  text-decoration: ${(props) => props.titleDecoration || "none"};
`;

const StyledDescription = styled.div`
  font-size: 0.7rem;
  color: ${(props) => props.theme.colorLabel};
`;

const TitleStack = ({ title, titleDecoration, description }) => (
  <div>
    <StyledTitle
      hasDescription={description && description.length > 0}
      titleDecoration={titleDecoration}
    >
      {title}
    </StyledTitle>
    <StyledDescription>{description}</StyledDescription>
  </div>
);

TitleStack.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  titleDecoration: PropTypes.string,
};

export default TitleStack;
