import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Card, MatTextbox, MatIcon } from "components/shared";
import LoginLogo from "./LoginLogo";
import { checkLogin } from "./actions";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colorPrimary};
`;

const StyledCard = styled(Card)`
  width: 320px;
  padding: 5rem 2rem 2rem;
`;

const StyledValidation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  margin-bottom: 2.25rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  background: #f05772;
  color: #ffffff;

  & > span {
    margin-right: 0.5rem;
  }
`;

const StyledLoginButton = styled.button`
  background: ${(props) => props.theme.colorPrimary};
  color: white;
  width: 100%;
  height: 50px;
  font-size: 1rem;
  border-radius: 0.375rem;

  &:disabled {
    background: #c2c2c2;
    cursor: not-allowed;
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV === "development") {
      this.state = {
        username: "dmytro@low6.co.uk",
        password: "Bb@12345",
      };
    } else {
      this.state = {
        username: "",
        password: "",
      };
    }
  }

  login = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.checkLogin(username, password);
  };

  handleTextboxChanged = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    const { username, password } = this.state;
    const { auth } = this.props;

    return (
      <StyledWrapper>
        <StyledCard>
          <LoginLogo />
          <form onSubmit={this.login}>
            {auth.error && (
              <StyledValidation>
                <MatIcon>error_outline</MatIcon>
                <p>Wrong username or password, please check and try again.</p>
              </StyledValidation>
            )}
            <MatTextbox
              onTextboxChanged={this.handleTextboxChanged}
              value={username}
              name="username"
              type="text"
              label="Username"
              icon="account_circle"
            />
            <MatTextbox
              onTextboxChanged={this.handleTextboxChanged}
              value={password}
              name="password"
              type="password"
              label="Password"
              icon="account_circle"
            />

            <StyledLoginButton
              type="submit"
              disabled={password.length === 0 || username.length === 0}
            >
              Login
            </StyledLoginButton>
          </form>
        </StyledCard>
      </StyledWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapActionsToProps = {
  checkLogin,
};

export default connect(mapStateToProps, mapActionsToProps)(Login);
