import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Card, MatTextbox } from "../../shared";

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${rgba("black", 0.5)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
  transition-property: visibility, opacity;
  transition-duration: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledModal = styled(Card)`
  width: 80%;
`;

const AddQuestions = (props) => {
  const [question, setQuestion] = useState({
    question: "",
    options: "",
    points: "",
  });
  const handleTextboxChanged = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setQuestion({
      ...question,
      [name]: value,
    });
  };
  const saveQuestion = () => {
    props.onSave(question);
    props.onClose();
  };
  return (
    <StyledWrapper onClick={props.onClose} isOpen={props.isOpen}>
      <StyledModal
        onClick={(e) => e.stopPropagation()}
        cancelAction={props.onClose}
        primaryAction={saveQuestion}
      >
        <div className="row">
          <div className="col-md-12">
            <p style={{ fontsize: "1.25rem !important" }}>
              Enter your question...
            </p>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "2rem" }}>
          <div
            className="col-md-8"
            style={{ paddingTop: "0.5rem", paddingBottom: "1rem" }}
          >
            <MatTextbox
              value={question.question}
              onTextboxChanged={handleTextboxChanged}
              label="Question"
              type="text"
              name="question"
            />
          </div>
          <div className="col-md-2" style={{ paddingTop: "0.5rem" }}>
            <MatTextbox
              value={question.options}
              onTextboxChanged={handleTextboxChanged}
              label="Options"
              type="text"
              name="options"
            />
          </div>
          <div
            className="col-md-2"
            style={{ paddingTop: "0.5rem", paddingBottom: "1rem" }}
          >
            <MatTextbox
              value={question.points}
              onTextboxChanged={handleTextboxChanged}
              label="Points"
              type="text"
              name="points"
            />
          </div>
        </div>
      </StyledModal>
    </StyledWrapper>
  );
};

export default AddQuestions;
