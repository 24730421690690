import { GET_FIXTURES, CLEAR_FIXTURES } from "./actions";

const initialState = {
  data: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FIXTURES:
      return {
        ...state,
        data: payload,
      };
    case CLEAR_FIXTURES:
      return {
        ...state,
        data: payload,
      };
    default:
      return state;
  }
}
