import axios from "axios";
import { handleError } from "../../../utils";

export const GET_FIXTURES = "fixtureTable:GET_FIXTURES";
export const CLEAR_FIXTURES = "fixtureTable:CLEAR_FIXTURES";

export const getFixtures = () => (dispatch) => {
  axios
    .get(`/football-matches?include_status=Fixture&period=PreMatch`)
    .then((res) => {
      const flattenedList = res.data.data.map((obj) => ({
        ...obj,
        homeTeamName: obj.homeTeam.name,
        awayTeamName: obj.awayTeam.name,
      }));

      dispatch({
        type: GET_FIXTURES,
        payload: flattenedList,
      });
    })
    .catch(handleError);
};

export function clearFixtures() {
  return {
    type: CLEAR_FIXTURES,
    payload: undefined,
  };
}
