import React from "react";
import styled from "styled-components";
import { MatIcon } from "../../../shared";

const StyledMenuButton = styled.button`
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 0.25rem;
  color: ${(props) => props.theme.colorLabel};

  &:hover {
    color: ${(props) => props.theme.colorLabelHover};
  }
`;

const StyledMenuButtonText = styled.span`
  margin-left: 0.5rem;
  font-size: 0.875rem;
`;

const TopbarMenuButton = ({ onClick, icon, text }) => (
  <StyledMenuButton onClick={onClick}>
    <MatIcon size="20px">{icon}</MatIcon>
    <StyledMenuButtonText>{text}</StyledMenuButtonText>
  </StyledMenuButton>
);

export default TopbarMenuButton;
