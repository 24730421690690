import axios from "axios";
import { toast } from "react-toastify";
import history from "router/history";
import { handleError } from "utils";

export const SET_FORM_FIELD = "team:SET_FORM_FIELD";
export const GET_TEAM = "team:GET_TEAM";
export const CLEAR_TEAM = "team:CLEAR_TEAM";
export const GET_PLAYER = "team:GET_PLAYER";
export const CLEAR_PLAYER = "team:CLEAR_PLAYER";
export const ADD_PLAYER = "team:ADD_PLAYER";
export const EDIT_PLAYER = "team:EDIT_PLAYER";
export const UPLOAD_PLAYER_IMAGE = "team:UPLOAD_PLAYER_IMAGE";
export const UPLOAD_TEAM_IMAGE = "team:UPLOAD_TEAM_IMAGE";

export function setFormField(name, value) {
  return {
    type: SET_FORM_FIELD,
    payload: {
      [name]: value,
    },
  };
}

export const getTeam = () => (dispatch) => {
  axios
    .get(`/football-players`)
    .then((res) => {
      dispatch({
        type: GET_TEAM,
        payload: res.data.data,
      });
    })
    .catch(handleError);
};

export const getPlayer = (player) => (dispatch) => {
  axios
    .get(`/football-players/${player}`)
    .then((res) => {
      dispatch({
        type: GET_PLAYER,
        payload: res.data.data,
      });
    })
    .catch(handleError);
};

export function clearTeam() {
  return {
    type: CLEAR_TEAM,
    payload: undefined,
  };
}

export const clearPlayer = () => (dispatch) => {
  dispatch({
    type: CLEAR_PLAYER,
    payload: undefined,
  });
};

export const addPlayer = (player) => (dispatch) => {
  axios
    .post(`/football-players`, player, {
      baseURL: process.env.REACT_APP_API_ENDPOINT,
    })
    .then((res) => {
      dispatch({
        type: ADD_PLAYER,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const editPlayer = (player) => (dispatch) => {
  axios
    .put(`/football-players/${player.id}`, player)
    .then((res) => {
      const { data } = res;
      toast.success(`${data.data.firstName} ${data.data.lastName} Updated!`);
      dispatch({
        type: EDIT_PLAYER,
        payload: data.data,
      });
      history.push("/team");
    })
    .catch(handleError);
};

export const uploadPlayerImage = (playerId, image) => (dispatch) => {
  const data = new FormData();
  data.append("image", new Blob([image]), "test.jpeg");

  axios
    .post(`/football-players/${playerId}/avatar`, data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    })
    .then((res) => {
      dispatch(getPlayer(playerId));
      dispatch({
        type: UPLOAD_PLAYER_IMAGE,
        payload: res,
      });
    })
    .catch(handleError);
};

export const uploadTeamImage = (teamId, image) => (dispatch) => {
  const data = new FormData();
  data.append("image", new Blob([image]), "test.jpeg");

  axios
    .post(`/football-teams/${teamId}/avatar`, data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    })
    .then((res) => {
      toast.success(`Image Uploaded: Please login again to see changes`);
      dispatch({
        type: UPLOAD_TEAM_IMAGE,
        payload: res,
      });
    })
    .catch(handleError);
};
