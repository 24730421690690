import React from "react";
import styled from "styled-components";
import { Card, CardHeader } from "components/shared";

const StyledBarRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
`;

const StyledBarLabel = styled.div`
  flex: 0 0 82px;
  font-weight: 500;
  color: ${(props) => props.theme.colorText};
`;

const StyledBar = styled.div`
  height: 10px;
  border-radius: 5px;
  background: ${(props) =>
    props.isZero ? props.theme.colorLightGrey : props.theme.colorPrimary};
`;

const DashboardBarCard = (props) => (
  <Card minHeight="284px">
    <CardHeader cardTitle={props.cardTitle} />
    {props.bars &&
      props.bars.map((bar) => (
        <StyledBarRow key={bar.title}>
          <StyledBarLabel>{bar.title}</StyledBarLabel>
          <StyledBar
            isZero={bar.percentage === "0"}
            style={{
              width: `${bar.percentage === "0" ? "5" : bar.percentage}%`,
            }}
          />
        </StyledBarRow>
      ))}
  </Card>
);

export default DashboardBarCard;
