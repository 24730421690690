import React from "react";
import styled, { css } from "styled-components";

const forTableStyles = css`
  border: 2px solid ${(props) => props.theme.colorBorder};
  padding: 0.5rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  height: 40px;
  border-radius: 0.25rem;
  color: ${(props) => props.theme.colorText};
`;

const StyledWrapper = styled.div`
  display: flex;

  border-bottom: 2px solid
    ${(props) =>
      props.isReadOnly
        ? props.theme.colorBorder
        : props.isRequired && !props.isValid
        ? props.theme.colorDanger
        : props.theme.colorBorder};

  ${(props) => props.forTable && "border: 0;"};
`;

const StyledPrefix = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-right: 0.325rem;
  font-size: 0.875rem;
  color: ${(props) => props.theme.colorLabel};
`;

const StyledInput = styled.input`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 56px;
  line-height: 56px;
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;

  ${(props) => props.forTable && forTableStyles};

  &::placeholder {
    color: ${(props) => props.theme.colorPlaceholder};
  }
`;

const FormInput = ({
  isReadOnly,
  isValid,
  isRequired,
  forTable,
  prefix,
  ...rest
}) => (
  <StyledWrapper
    forTable={forTable}
    isReadOnly={isReadOnly}
    isValid={isValid}
    isRequired={isRequired}
  >
    {prefix && <StyledPrefix>{prefix}</StyledPrefix>}
    <StyledInput disabled={isReadOnly} forTable={forTable} {...rest} />
  </StyledWrapper>
);

export default FormInput;
