import React from "react";
import styled from "styled-components";

const StyledAlert = styled.div`
  padding: 1.25rem;
  border-radius: 0.5rem;
  background: #ffefef;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #dc9d9d;
`;

const Alert = ({ children }) => <StyledAlert>{children}</StyledAlert>;

export default Alert;
