import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Skeleton, Card } from "../";

const SkeletonTable = ({ items }) => {
  const rows = new Array(items).fill(0);

  return (
    <Card>
      <Skeleton display="block" margin="0.875rem 0 2.75rem" />
      <Skeleton display="block" skeletonWidth="600px" margin="0 0 3rem" />
      {rows.map((i, index) => (
        <div key={`key-${index}`}>
          <Skeleton display="block" skeletonWidth="80%" margin="0 0 2rem" />
          <Skeleton display="block" skeletonWidth="45%" margin="0 0 2rem" />
          <Skeleton display="block" skeletonWidth="20%" margin="0 0 2rem" />
        </div>
      ))}
    </Card>
  );
};

SkeletonTable.defaultProps = {
  items: 6,
};

SkeletonTable.propTypes = {
  items: PropTypes.number,
};

export default SkeletonTable;
