import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props) => props.theme.colorLabel};
`;

const CardHeaderTitle = (props) => (
  <StyledWrapper>
    <StyledTitle>{props.children}</StyledTitle>
  </StyledWrapper>
);

export default CardHeaderTitle;
