import React from "react";
import styled from "styled-components";

const StyledText = styled.span`
  color: ${(props) => props.theme.colorLabel};
`;

const LabelText = ({ children, style }) => (
  <StyledText style={style}>{children}</StyledText>
);

export default LabelText;
