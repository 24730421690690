import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { ReactSortable } from "react-sortablejs";
import {
  Card,
  CardHeader,
  CardHeaderTitle,
  CardFooter,
  IconButton,
  DefaultButton,
  FadeIn,
  Skeleton,
  SkeletonTable,
  TitleStack,
  Chip,
  Alert,
  BlockButton,
} from "components/shared";
import { Table, Thead, Tbody, Tr, Td, Th } from "components/shared/table/Table";
import EditableRow from "./EditableRow";
import {
  getContest,
  updateQuestions,
  clearQuestions,
  deleteQuestion,
  saveContestQuestions,
  setQuestionsForDelete,
} from "./actions";
import { getQuestions as getAllQuestions } from "../questions/actions";
import { setActiveModal } from "components/layout/overlay/actions";

import ContestSortModal from "./ContestSortModal";

const AddContest = (props) => {
  const { fixtureId, contestId } = props.match.params;
  const {
    contest,
    getAllQuestions,
    getQuestions,
    getContest,
    saveContestQuestions,
    clearQuestions,
    questionTemplates,
    players,
    answerOptions,
    isSavingQuestions,
    isDeletingQuestion,
    setQuestionsForDelete,
    questionsForDelete,
  } = props;

  const dispatch = useDispatch();

  var data = contest.questions;

  const numberOptions = [];
  for (let i = 0; i < 8; i++) {
    numberOptions.push({ key: i, value: i + 1, text: i + 1 });
  }

  const questionOptions =
    questionTemplates &&
    questionTemplates.map((obj) => ({
      key: obj.id,
      value: obj.id,
      text: obj.formattedTitle,
    }));

  const playerOptions =
    players &&
    players.map((obj, index) => ({
      key: `${index}-${obj.id}`,
      value: obj.id,
      text: `${obj.firstName} ${obj.lastName}`,
    }));

  let periodOptions = [];
  if (answerOptions) {
    periodOptions = Object.values(answerOptions.FOOTBALL_PERIODS).map(
      (obj, index) => ({
        key: index,
        value: obj,
        text: obj,
      })
    );
  }

  useEffect(() => {
    getContest(contestId);
    getAllQuestions();
    return () => clearQuestions();
  }, [getAllQuestions, getContest, clearQuestions, fixtureId]);

  const addQuestion = () => {
    data.push({
      id: -1,
      number: 0,
      availablePoints: 1,
      pickemId: +contestId,
      questionTemplateId: questionTemplates[0].id,
      playerId: players[0].id,
    });

    props.updateQuestions(fixtureId, data);
  };

  const cancel = () =>
    props.history.push(`/fixtures/${fixtureId}/${contestId}`);

  const save = () => saveContestQuestions(contestId, data, questionsForDelete);

  const sort = () => {
    dispatch(setActiveModal("sort-questions"));
  };

  const handleQuestionChanged = (e, index) => {
    const { value } = e.target;
    const questionText = questionTemplates.find((q) => q.id === +value).title;

    const hasPlayer = questionText.indexOf("<Player Name>") > -1;
    const hasNumber = questionText.indexOf("<Number>") > -1;
    const hasPeriod = questionText.indexOf("<Period>") > -1;

    data[index].questionTemplateId = +value;

    if (hasPlayer) {
      data[index].playerId = +playerOptions[0].value;
    }

    if (hasNumber) {
      data[index].number = +numberOptions[0].value;
    }

    if (hasPeriod) {
      data[index].period = periodOptions[0].value;
    }

    props.updateQuestions(fixtureId, data);
  };

  const handlePlayerChanged = (e, index) => {
    const { value } = e.target;
    data[index].playerId = players.find((p) => p.id === +value).id;
    props.updateQuestions(fixtureId, data);
  };

  const handleNumberChanged = (e, index) => {
    const { value } = e.target;
    data[index].number = +value;
    props.updateQuestions(fixtureId, data);
  };

  const handlePointsChanged = (e, index) => {
    const { value } = e.target;
    data[index].availablePoints = +value;
    props.updateQuestions(fixtureId, data);
  };

  const handlePeriodChanged = (e, index) => {
    const { value } = e.target;
    data[index].period = value;
    props.updateQuestions(fixtureId, data);
  };

  const handleSubtitleChanged = (e, index) => {
    const { value } = e.target;
    data[index].subtitle = value;
    props.updateQuestions(fixtureId, data);
  };

  const handleDeleteClicked = (contestId, questionId, isRestore) => {
    if (isRestore) {
      questionsForDelete.splice(
        questionsForDelete.findIndex((obj) => obj === questionId) > 0
      );
    } else {
      questionsForDelete.push(questionId);
    }

    setQuestionsForDelete(questionsForDelete);
  };

  const handleRemoveClicked = (index) => {
    data.splice(index, 1);
    props.updateQuestions(fixtureId, data);
  };

  const markedForDelete = (questionId) => {
    return questionsForDelete.indexOf(questionId) > -1;
  };

  const isApproved =
    contest &&
    contest.activeContest &&
    contest.activeContest.status === "APPROVED";

  const formIsDirty =
    questionsForDelete.length > 0 || (data && data.some((q) => q.id === -1));

  const saveButtonDisabled = isApproved || !formIsDirty;
  const saveButtonTooltip = isApproved
    ? "You can't change a published contest"
    : !formIsDirty
    ? "No changes to save"
    : undefined;

  const isEmpty = data && data.length === 0;

  const orderButtonDisabled = isApproved || formIsDirty || isEmpty;
function deleter(){
  if(isApproved){
    return <div></div>
  }else{
    return 
  }
}
  const orderButtonTooltip = isApproved
    ? "You can't change a published contest"
    : formIsDirty
    ? "You have unsaved changes"
    : isEmpty
    ? "Add some questions first"
    : undefined;

  return !data ? (
    <SkeletonTable items={3} />
  ) : (
    <>
      <ContestSortModal />
      <Card>
        <FadeIn>
          <CardHeader borderBottom marginBottom="0">
            <CardHeaderTitle>
              {contest.activeContest.name} Questions
            </CardHeaderTitle>
            <Chip tooltip="Status">{contest.activeContest.status}</Chip>
          </CardHeader>
          {data && data.length > 0 ? (
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ width: "36px" }}>#</Th>
                  <Th>Question</Th>
                  <Th style={{ width: "80px" }}>Points</Th>
                  <Th style={{ width: "50px" }}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((q, index) =>
                  q.id === -1 ? (
                    <EditableRow
                      key={`index-${index}`}
                      index={index}
                      selectedQuestion={
                        questionTemplates.find(
                          (obj) => obj.id === q.questionTemplateId
                        ).title
                      }
                      subtitleOnChange={handleSubtitleChanged}
                      questionOnChange={handleQuestionChanged}
                      questionOptions={questionOptions}
                      playerOnChange={handlePlayerChanged}
                      numberOnChange={handleNumberChanged}
                      periodOnChange={handlePeriodChanged}
                      pointsOnChange={handlePointsChanged}
                      playerOptions={playerOptions}
                      numberOptions={numberOptions}
                      periodOptions={periodOptions}
                      removeClicked={handleRemoveClicked}
                    />
                  ) : (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>
                        {!q.label && <Skeleton skeletonWidth="350px" />}
                        {q.label && (
                          <TitleStack
                            title={q.label}
                            description={q.subtitle}
                            titleDecoration={
                              markedForDelete(q.id) ? "line-through" : -1
                            }
                          />
                        )}
                      </Td>
                      <Td>{q.availablePoints}</Td>
                      <Td style={{ textAlign: "right" }}>
                        {!isApproved && <IconButton
                          disabled={isDeletingQuestion === q.id}
                          isLoading={isDeletingQuestion === q.id}
                          onClick={() =>
                            handleDeleteClicked(
                              contestId,
                              q.id,
                              markedForDelete(q.id)
                            )
                          }
                          tooltip={markedForDelete(q.id) ? "Undo" : "Delete"}
                        >
                          {markedForDelete(q.id) ? "undo" : "delete"}
                        </IconButton> }
                        
                      </Td>
                      {process.env.NODE_ENV === "test" && (
                        <>
                          <Td>{q.id}</Td>
                          <Td>{q.order}</Td>
                        </>
                      )}
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
          ) : (
            <div style={{ paddingTop: "1rem" }}>
              <Alert>You have not added any questions yet</Alert>
            </div>
          )}

          <CardFooter
            borderTop
            justifyContent={isApproved ? "flex-end" : "space-between"}
          >
            {!isApproved && (
              <DefaultButton onClick={addQuestion} icon="add_circle">
                Add Question
              </DefaultButton>
            )}

            <div>
              <BlockButton onClick={cancel}>
                {isApproved ? "Back" : formIsDirty ? "Cancel" : "Back"}
              </BlockButton>
              <BlockButton
                disabled={orderButtonDisabled}
                tooltip={orderButtonTooltip}
                onClick={sort}
              >
                Order
              </BlockButton>
              <BlockButton
                tooltip={saveButtonTooltip}
                isPrimary
                isLoading={isSavingQuestions}
                onClick={save}
                disabled={saveButtonDisabled}
              >
                Save
              </BlockButton>
            </div>
          </CardFooter>
        </FadeIn>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  contest: state.contest,
  isSavingQuestions: state.contest.isSavingQuestions,
  isDeletingQuestion: state.contest.isDeletingQuestion,
  questionTemplates: state.questions.questionTemplates,
  players: state.questions.players,
  answerOptions: state.questions.answerOptions,
  questionsForDelete: state.contest.questionsForDelete,
});

const mapActionsToProps = {
  updateQuestions,
  getContest,
  clearQuestions,
  deleteQuestion,
  getAllQuestions,
  saveContestQuestions,
  setQuestionsForDelete,
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(AddContest)
);
