import React from "react";
import styled from "styled-components";
import CardHeaderTitle from "./CardHeaderTitle";

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  margin-bottom: ${(props) => props.marginBottom || "1rem"};

  ${(props) =>
    props.borderBottom &&
    `height: auto; padding-top: 0.3rem; padding-bottom: 1rem; border-bottom: 2px solid ${props.theme.colorBorder}`};
`;

const CardHeader = ({ children, cardTitle, borderBottom, marginBottom }) => (
  <StyledHeader borderBottom={borderBottom} marginBottom={marginBottom}>
    {cardTitle && <CardHeaderTitle>{cardTitle}</CardHeaderTitle>}
    {children}
  </StyledHeader>
);

export default CardHeader;
