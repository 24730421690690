import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import { ToastContainer, cssTransition } from "react-toastify";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { theme } from "../themes";
import history from "../router/history";

import Header from "./layout/header/Wrapper";
import Content from "./layout/content/Content";
import Overlay from "./layout/overlay/Overlay";

import Login from "./feature/auth/Login";
import Dashboard from "./feature/dashboard/Dashboard";
import Team from "./feature/team/Team";
import AddPlayer from "./feature/team/addPlayer/AddPlayer";
import Fixtures from "./feature/fixtures/Fixtures";
import ContestEdit from "./feature/contest/ContestEdit";
import ContestQuestions from "./feature/contest/ContestQuestions";
import Leaderboard from "./feature/leaderboard/Leaderboard";
import Questions from "./feature/questions/Questions";
import Users from "./feature/users/Users";
import { loadFromLocalStorage } from "./feature/auth/actions";

const slideTransition = cssTransition({
  enter: "slideUp",
  exit: "slideDown",
  duration: 250,
});

const GlobalStyle = createGlobalStyle`
  body {
    color: ${(props) => props.theme.colorText};;
    background: ${(props) => props.theme.colorBackground};
  }

  button, input, select {
    color: ${(props) => props.theme.colorText};
  }

  body .Toastify__toast {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  body .Toastify__toast-container {
    z-index: 4;
    bottom: -1rem;
    padding: 0;
  }

  body .Toastify__toast--success {
    background: ${(props) => props.theme.colorSuccess};
  }

  body .Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
  }

  body .Toastify__toast-body {
    text-align:center;
    font-family: ${(props) => props.theme.fontFamily};
  }

  body .apexcharts-tooltip.apexcharts-tooltip.apexcharts-theme-light {
    border-color: ${(props) => props.theme.colorBorder};
    box-shadow: ${(props) => props.theme.boxShadowCard};
  }

  body .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: white;
    padding: 1rem;
    color: ${(props) => props.theme.colorLabel};
    border-bottom-color: ${(props) => props.theme.colorBorder};;
  }

  body .apexcharts-tooltip-series-group.apexcharts-active {
    padding: 0.5rem 1rem;
  }
`;

function Root({ loadFromLocalStorage }) {
  useEffect(() => {
    loadFromLocalStorage();
  }, [loadFromLocalStorage]);

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Overlay />
        <GlobalStyle />
        <ToastContainer
          limit={1}
          transition={slideTransition}
          autoClose={3000}
          position="bottom-center"
          hideProgressBar
        />
        <Header />
        <Content>
          <Switch>
            <Route path="/" exact>
              <Login />
            </Route>
            <Route path="/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/team" exact>
              <Team />
            </Route>
            <Route path="/team/:player" exact>
              <AddPlayer />
            </Route>
            <Route path="/fixtures" exact>
              <Fixtures />
            </Route>
            <Route path="/fixtures/:fixtureId/:contestId" exact>
              <ContestEdit />
            </Route>
            <Route
              path="/fixtures/:fixtureId/:homeTeam/:awayTeam/:contestId"
              exact
            >
              <ContestEdit />
            </Route>
            <Route path="/fixtures/:fixtureId/:contestId/questions" exact>
              <ContestQuestions />
            </Route>
            <Route path="/leaderboard" exact>
              <Leaderboard />
            </Route>
            <Route path="/questions" exact>
              <Questions />
            </Route>
            <Route path="/users" exact>
              <Users />
            </Route>
          </Switch>
        </Content>
      </ThemeProvider>
    </Router>
  );
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = {
  loadFromLocalStorage,
};

export default connect(mapStateToProps, mapActionsToProps)(Root);
