import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../../shared";

const StyledWrapper = styled.div`
  padding: 1rem 0 0;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    padding: 1.75rem 0 0;
  }
`;

const Content = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <StyledWrapper>
      <Container noFlex>{props.children}</Container>
    </StyledWrapper>
  );
};

export default Content;
