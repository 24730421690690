import React from "react";
import styled, { css } from "styled-components";
import { DebounceInput } from "react-debounce-input";
import { MatIcon } from "../";

const StyledWrapper = styled.span`
  position: relative;
  width: 100%;
`;

const forTableStyles = css`
  border: 2px solid ${(props) => props.theme.colorBorder};
  padding: 0.5rem 0.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  height: 40px;
  border-radius: 0.25rem;
  color: ${(props) => props.theme.colorText};
`;

const StyledIcon = styled(MatIcon)`
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
`;

const StyledInput = styled(DebounceInput)`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 56px;
  line-height: 56px;
  text-indent: ${(props) => (props.icon ? "1.5rem" : 0)};
  background: transparent;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;

  border-bottom: 2px solid
    ${(props) =>
      props.isReadOnly
        ? props.theme.colorBorder
        : props.isRequired && !props.isValid
        ? props.theme.colorDanger
        : props.theme.colorBorder};

  ${(props) => props.forTable && forTableStyles};

  &::placeholder {
    color: ${(props) => props.theme.colorPlaceholder};
  }
`;

const FormInput = ({
  isReadOnly,
  forTable,
  onChange,
  debounceTimeout,
  minLength,
  name,
  value,
  placeholder,
  icon,
}) => (
  <StyledWrapper>
    {icon && <StyledIcon size="18px">{icon}</StyledIcon>}
    <StyledInput
      icon={icon}
      debounceTimeout={debounceTimeout || 300}
      onChange={onChange}
      minLength={minLength}
      name={name}
      disabled={isReadOnly}
      forTable={forTable}
      placeholder={placeholder}
    />
  </StyledWrapper>
);

export default FormInput;
