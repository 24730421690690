import React, { Component } from "react";
import Chart from "react-apexcharts";
import { Card, CardHeader } from "components/shared";
import { withTheme } from "styled-components";
import moment from "moment";

class DashboardLineCard extends Component {
  constructor(props) {
    super(props);

    const { theme } = props;

    this.state = {
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        grid: {
          strokeDashArray: 5,
          borderColor: theme.colorBorder,
          xaxis: {
            lines: { show: true },
          },
          yaxis: {
            lines: { show: false },
          },
        },
        fill: {
          gradient: {
            type: "vertical",
            shadeIntensity: 0.5,
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                opacity: 0.375,
                color: theme.colorPrimary,
              },
              { offset: 100, opacity: 0, color: "white" },
            ],
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              return value.toFixed(0);
            },
          },
          followCursor: true,
          style: {
            fontFamily: "Poppins",
          },
        },
        stroke: {
          width: 4,
          curve: "smooth",
          lineCap: "round",
          colors: [theme.colorPrimary],
        },
        markers: {
          size: 5,
          strokeWidth: 3,
          colors: ["white"],
          strokeColors: theme.colorPrimary,
          hover: {
            size: 7,
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: theme.colorLabel,
              fontFamily: theme.fontFamily,
              fontWeight: 500,
            },
            formatter: function (value, timestamp, opts) {
              return value.toFixed(0);
            },
            align: "left",
            minWidth: 30,
          },
          axisBorder: {
            show: false,
          },
        },
        xaxis: {
          crosshairs: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          labels: {
            style: {
              colors: theme.colorLabel,
              fontFamily: theme.fontFamily,
              fontWeight: 500,
            },
            formatter: function (value, timestamp, opts) {
              return moment(value, "DD/MM/YYYY").format("Do MMM");
            },
            offsetY: 5,
            minHeight: 40,
          },
          axisBorder: {
            show: false,
          },
          categories: props.data.map((obj) => obj.label),
        },
      },
      series: [
        {
          name: "Entries Per Contest",
          data: props.data.map((obj) => obj.value),
        },
      ],
    };
  }

  render() {
    const { cardTitle } = this.props;

    return (
      <Card>
        <CardHeader cardTitle={cardTitle} />
        <Chart
          height="260px"
          options={this.state.options}
          series={this.state.series}
          type="area"
        />
      </Card>
    );
  }
}

export default withTheme(DashboardLineCard);
