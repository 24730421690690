import React from "react";
import styled from "styled-components";

const StyledFooter = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "space-between"};
  ${(props) =>
    props.borderTop &&
    `padding-top: 1rem; border-top: 2px solid ${props.theme.colorBorder}`};
`;

const CardFooter = ({ children, justifyContent, borderTop }) => (
  <StyledFooter borderTop={borderTop} justifyContent={justifyContent}>
    {children}
  </StyledFooter>
);

export default CardFooter;
