import { SET_OVERLAY_ISOPEN, SET_ACTIVE_MODAL } from "./actions";

const initialState = {
  isOpen: false,
  activeModal: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_OVERLAY_ISOPEN:
      return {
        ...state,
        isOpen: payload,
      };
    case SET_ACTIVE_MODAL:
      return {
        ...state,
        isOpen: payload ? true : false,
        activeModal: payload,
      };
    default:
      return state;
  }
}
