import React from "react";
import styled from "styled-components";

const StyledLabel = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.colorLabel};
`;

const FormRowLabel = ({ children }) => <StyledLabel>{children}</StyledLabel>;

export default FormRowLabel;
