import axios from "axios";
import history from "../../../router/history";
import { theme } from "../../../themes";

export const CHECK_LOGIN = "auth:CHECK_LOGIN";
export const LOAD_FROM_LOCAL_STORAGE = "auth:LOAD_FROM_LOCAL_STORAGE";

export const checkLogin = (username, password) => (dispatch) => {
  //axios.defaults.headers.common["X-APP-ID"] = "Low6-NCFC/NCFC";

  axios
    .post(`/auth/login`, { email: username, password })
    .then(({ data }) => {
      const { access_token } = data;
      axios.defaults.headers.common["Authorization"] = `bearer ${access_token}`;
      localStorage.setItem("access_token", access_token);

      if (data.app_configs) {
        console.log("colorTeamBackground", data.app_configs.backgroundColor);

        localStorage.setItem(
          "colorTeamBackground",
          data.app_configs.backgroundColor || "#3283e9"
        );

        console.log("colorTeamText", data.app_configs.textColor);

        localStorage.setItem(
          "colorTeamText",
          data.app_configs.textColor || "white"
        );
      }

      if (data.user) {
        const name = `${data.user.first_name} ${data.user.last_name}`;
        localStorage.setItem("userFullName", name);
      }

      if (data.tenant) {
        axios.defaults.headers.common["X-APP-ID"] = data.tenant.userAgentPrefix;
        localStorage.setItem("userAgentPrefix", data.tenant.userAgentPrefix);

        if (data.tenant.footballTeam) {
          localStorage.setItem("teamId", data.tenant.footballTeam.id);
          localStorage.setItem("teamName", data.tenant.footballTeam.name);
          localStorage.setItem("teamLogo", data.tenant.footballTeam.logo);
        }
      }

      dispatch(loadFromLocalStorage());
      history.push("/dashboard");
    })
    .catch(({ response }) => {
      if (response) {
        dispatch({
          type: CHECK_LOGIN,
          payload: { error: response.data.error },
        });
      }
    });
};

export const logout = () => (dispatch) => {
  axios
    .post(`/auth/logout`)
    .then(({ data }) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("teamLogo");
      localStorage.removeItem("colorTeamBackground");
      localStorage.removeItem("colorTeamText");

      dispatch(loadFromLocalStorage());

      history.push("/");
    })
    .catch((error) => {
      localStorage.removeItem("access_token");
      history.push("/");
    });
};

export const loadFromLocalStorage = () => (dispatch) => {
  const teamLogo = localStorage.getItem("teamLogo");
  const teamName = localStorage.getItem("teamName");
  const colorTeamBackground = localStorage.getItem("colorTeamBackground");
  const colorTeamText = localStorage.getItem("colorTeamText");
  const teamId = localStorage.getItem("teamId");
  const userAgentPrefix = localStorage.getItem("userAgentPrefix");
  const userFullName = localStorage.getItem("userFullName");

  dispatch({
    type: LOAD_FROM_LOCAL_STORAGE,
    payload: {
      userAgentPrefix,
      userFullName,
      teamLogo,
      teamName,
      teamId: +teamId,
      colorTeamBackground: colorTeamBackground || theme.colorPrimary,
      colorTeamText: colorTeamText || "white",
    },
  });
};
