import React from "react";
import styled from "styled-components";

const StyledLabel = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.colorLabel};
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 0;
  border-radius: 0.25rem;
  line-height: 56px;
  height: 56px;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};

  &:focus {
    border-color: ${(props) => props.theme.colorPrimary};
  }
`;

const FormDropdown = ({ label, options, onChange, name, value }) => (
  <>
    <StyledLabel>{label}</StyledLabel>
    <StyledSelect onChange={onChange} name={name} value={value}>
      {options &&
        options.map((o) => (
          <option key={o.key} value={o.value}>
            {o.text}
          </option>
        ))}
    </StyledSelect>
  </>
);

export default FormDropdown;
