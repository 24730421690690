import React from "react";
import styled from "styled-components";
import { Table, Thead, Tbody, Tr, Td, Th } from "components/shared/table/Table";
import { Dropdown, FormInput, IconButton } from "components/shared";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledBlock = styled.div`
  flex: ${({ flex }) => flex};
  padding-right: 1rem;
`;

const EditableRow = (props) => {
  const {
    index,
    selectedQuestion,
    questionOptions,
    numberOptions,
    teamOptions,
    playerOptions,
    periodOptions,
    questionOnChange,
    playerOnChange,
    numberOnChange,
    periodOnChange,
    pointsOnChange,
    subtitleOnChange,
    removeClicked,
  } = props;

  if (!questionOptions) {
    return null;
  }

  const hasPlayerOption = selectedQuestion.indexOf("<Player Name>") > -1;
  const hasNumberOption = selectedQuestion.indexOf("<Number>") > -1;
  const hasPeriodOption = selectedQuestion.indexOf("<Period>") > -1;

  return (
    <Tr>
      <Td>{index + 1}</Td>
      <Td style={{ paddingRight: 0 }}>
        <StyledWrapper>
          <StyledBlock flex={3}>
            <Dropdown
              tooltip="Question"
              onChange={(e) => questionOnChange(e, index)}
              options={questionOptions}
              forTable
              stretch
            />
          </StyledBlock>
          {hasPlayerOption && (
            <StyledBlock flex={2}>
              <Dropdown
                tooltip="Player"
                onChange={(e) => playerOnChange(e, index)}
                options={playerOptions}
                forTable
                stretch
              />
            </StyledBlock>
          )}
          {hasNumberOption && (
            <StyledBlock flex={1}>
              <Dropdown
                tooltip="Number"
                onChange={(e) => numberOnChange(e, index)}
                options={numberOptions}
                forTable
                stretch
              />
            </StyledBlock>
          )}
          {hasPeriodOption && (
            <StyledBlock flex={2}>
              <Dropdown
                tooltip="Period"
                onChange={(e) => periodOnChange(e, index)}
                options={periodOptions}
                forTable
                stretch
              />
            </StyledBlock>
          )}
          <StyledBlock flex={2}>
            <FormInput
              placeholder="Hints"
              forTable
              isSmall
              type="text"
              onChange={(e) => subtitleOnChange(e, index)}
            />
          </StyledBlock>
        </StyledWrapper>
      </Td>

      <Td>
        <Dropdown
          tooltip="Available Points"
          onChange={(e) => pointsOnChange(e, index)}
          options={numberOptions}
          forTable
          stretch
        />
      </Td>
      <Td>
        <IconButton onClick={() => removeClicked(index)} tooltip="Delete">
          delete
        </IconButton>
      </Td>
    </Tr>
  );
};

export default EditableRow;
