import React from "react";
import styled from "styled-components";
import CardHeader from "./CardHeader";
import CardHeaderTitle from "./CardHeaderTitle";
import CardFooter from "./CardFooter";
import CardComingSoon from "./CardComingSoon";
import {
  MatIcon,
  Dropdown,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
  Chip,
} from "../../shared";
import PropTypes from "prop-types";

const StyledCard = styled.div`
  position: relative;
  min-height: ${(props) => props.minHeight || "auto"};
  padding: 0.75rem 1.25rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background: #ffffff;
  box-shadow: ${(props) => props.theme.boxShadowCard};
  overflow: ${(props) => (props.comingSoon ? "hidden" : "auto")};

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    margin-bottom: 1.75rem;
  }
`;

const Card = ({
  children,
  className,
  style,
  cardTitle,
  comingSoon,
  minHeight,
}) => (
  <StyledCard
    comingSoon={comingSoon}
    className={className}
    style={style}
    minHeight={minHeight}
  >
    {comingSoon && <CardComingSoon />}
    {children}
  </StyledCard>
);

Card.propTypes = {
  minHeight: PropTypes.string,
  comingSoon: PropTypes.bool,
};

export default Card;
