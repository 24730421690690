import React from "react";
import styled from "styled-components";
import { MatIcon } from "../";

const StyledLabel = styled.label`
  cursor: pointer;
`;

const StyledImageWrapper = styled.div`
  position: relative;
  width: 50%;
  padding: 1rem;
  border-radius: 1rem;
  border: 2px solid ${(props) => props.theme.colorBorder};
  margin-bottom: 1rem;
`;

const StyledImage = styled.img`
  width: 100%;
`;

const StyledLinkText = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props) => props.theme.colorPrimary};
  margin-bottom: 1rem;
`;

const StyledFileInfo = styled.div`
  font-size: 0.75rem;
  color: ${(props) => props.theme.colorLabel};
  margin-bottom: 1rem;
`;

const StyledAddIcon = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colorPrimary};
  border: 2px solid white;
  color: white;
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;

const noop = () => {};

const FileInput = ({
  emptyImage,
  value,
  onChange = noop,
  imageUrl,
  recommendedSize,
  ...rest
}) => (
  <StyledLabel>
    <StyledImageWrapper>
      <StyledAddIcon>
        <MatIcon size="16px">add</MatIcon>
      </StyledAddIcon>
      {!imageUrl && emptyImage && <StyledImage src={emptyImage} />}
      {imageUrl && <StyledImage src={imageUrl} />}
    </StyledImageWrapper>
    <StyledLinkText>Upload Image</StyledLinkText>
    <StyledFileInfo>
      Recommended Size: {recommendedSize || "400px x 400px"}
    </StyledFileInfo>
    <input
      {...rest}
      style={{ display: "none" }}
      type="file"
      onChange={(e) => {
        onChange([...e.target.files]);
      }}
    />
  </StyledLabel>
);

export default FileInput;
