import React from "react";
import styled from "styled-components";
import { Table, Thead, Tbody, Tr, Td, Th } from "components/shared/table/Table";
import {
  IconButton,
  TableSortButton,
  FormDebounceInput,
  LabelText,
} from "components/shared";
import avatar from "assets/images/avatar.png";

const StyledAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url(${(props) => props.image});
  background-size: cover;
`;

const TeamPlayers = ({
  data,
  sortButtonClicked,
  searchChanged,
  sortDirection,
  sortField,
}) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th style={{ width: "15%", padding: 0 }}>Image</Th>
          <Th style={{ width: "25%", padding: 0 }}>
            <TableSortButton
              onClick={sortButtonClicked}
              sortField="firstName"
              sortDirection={sortDirection}
              isSorted={sortField === "firstName"}
            >
              First Name
            </TableSortButton>
          </Th>
          <Th style={{ width: "30%", padding: 0 }}>
            <TableSortButton
              onClick={sortButtonClicked}
              sortField="lastName"
              sortDirection={sortDirection}
              isSorted={sortField === "lastName"}
            >
              Last Name
            </TableSortButton>
          </Th>
          <Th style={{ width: "30%", padding: "0.5rem 0" }}>
            <FormDebounceInput
              minLength={1}
              onChange={searchChanged}
              forTable
              placeholder="Search"
              icon="search"
            />
          </Th>
        </Tr>
      </Thead>
      {data.length > 0 && (
        <Tbody>
          {data.map((player, index) => (
            <Tr key={`${index}-${player.id}`}>
              <Td>
                <StyledAvatar image={player.avatar || avatar} />
              </Td>
              <Td>{player.firstName}</Td>
              <Td>{player.lastName}</Td>
              <Td style={{ textAlign: "right" }}>
                <IconButton tooltip="Edit" to={`/team/${player.id}`}>
                  edit
                </IconButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      )}
      {data.length === 0 && (
        <Tbody>
          <Tr>
            <Td style={{ padding: "30px 0" }} colSpan={4}>
              <LabelText>No players match this search</LabelText>
            </Td>
          </Tr>
        </Tbody>
      )}
    </Table>
  );
};
export default TeamPlayers;
