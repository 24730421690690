import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { getUsers } from "./actions";
import { Table, Thead, Tbody, Tr, Td, Th } from "components/shared/table/Table";
import {
  Card,
  CardHeader,
  LinkButton,
  FadeIn,
  FormattedDate,
  PenceToPounds,
  MatIcon,
  SkeletonTable,
  TableSortButton,
} from "components/shared";
import { useSorting } from "components/hooks";

const Users = ({ users, getUsers }) => {
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const getAge = (date) => {
    return moment().diff(date, "years");
  };

  const { data } = users;

  const [sortField, sortDirection, sortButtonClicked, sortedItems] = useSorting(
    "email",
    "asc",
    data
  );

  return data ? (
    <Card>
      <FadeIn>
        <CardHeader cardTitle="Users" />
        <Table minWidth="1000px">
          <Thead isSortable>
            <Tr>
              <Th>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="email"
                  sortDirection={sortDirection}
                  isSorted={sortField === "email"}
                >
                  Email
                </TableSortButton>
              </Th>
              <Th>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="username"
                  sortDirection={sortDirection}
                  isSorted={sortField === "username"}
                >
                  Username
                </TableSortButton>
              </Th>
              <Th>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="created"
                  sortDirection={sortDirection}
                  isSorted={sortField === "created"}
                >
                  Date Added
                </TableSortButton>
              </Th>
              <Th>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="firstName"
                  sortDirection={sortDirection}
                  isSorted={sortField === "firstName"}
                >
                  First Name
                </TableSortButton>
              </Th>
              <Th>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="lastName"
                  sortDirection={sortDirection}
                  isSorted={sortField === "lastName"}
                >
                  Last Name
                </TableSortButton>
              </Th>
              <Th style={{ width: "80px" }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="birthDate"
                  sortDirection={sortDirection}
                  isSorted={sortField === "birthDate"}
                >
                  Age
                </TableSortButton>
              </Th>
              <Th style={{ width: "80px" }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="winningPickemsCount"
                  sortDirection={sortDirection}
                  isSorted={sortField === "winningPickemsCount"}
                >
                  Wins
                </TableSortButton>
              </Th>
              <Th style={{ width: "120px" }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="winningsAmount"
                  sortDirection={sortDirection}
                  isSorted={sortField === "winningsAmount"}
                >
                  Winnings
                </TableSortButton>
              </Th>
              <Th>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="identityPassed"
                  sortDirection={sortDirection}
                  isSorted={sortField === "identityPassed"}
                >
                  ID3
                </TableSortButton>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((user) => (
              <Tr>
                <Td>{user.email}</Td>
                <Td>{user.username}</Td>
                <Td>
                  <FormattedDate>{user.created}</FormattedDate>
                </Td>
                <Td>{user.firstName}</Td>
                <Td>{user.lastName}</Td>
                <Td>{getAge(user.birthDate)}</Td>
                <Td>{user.winningPickemsCount}</Td>
                <Td>
                  £<PenceToPounds>{user.winningsAmount}</PenceToPounds>
                </Td>

                <Td>
                  <MatIcon>{user.identityPassed ? "check" : "close"}</MatIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </FadeIn>
    </Card>
  ) : (
    <SkeletonTable />
  );
};

const mapStateToProps = (state) => ({
  users: state.users,
});

const mapActionsToProps = {
  getUsers,
};

export default connect(mapStateToProps, mapActionsToProps)(Users);
