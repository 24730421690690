import React from "react";
import styled from "styled-components";
import { MatIcon } from "../";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 1rem 0;
  color: #aab2bd;
  width: 100%;
`;

const TableSortButton = ({
  children,
  sortField,
  sortDirection,
  isSorted,
  onClick,
  hideIcon,
}) => {
  const handleClick = () => {
    onClick(sortField, sortDirection);
  };

  return (
    <StyledButton onClick={handleClick}>
      {children}
      {isSorted && !hideIcon && (
        <MatIcon style={{ marginLeft: "0.5rem" }} size="16px">
          {sortDirection === "asc" ? "arrow_upward" : "arrow_downward"}
        </MatIcon>
      )}
    </StyledButton>
  );
};

export default TableSortButton;
