import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import Root from "./components/Root";

import "react-tippy/dist/tippy.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/bootstrap.css";

function App() {
  return (
    <Provider store={store}>
      <Root />
    </Provider>
  );
}

export default App;
