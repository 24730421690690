import React from "react";
import styled from "styled-components";

const StyledText = styled.span`
  color: ${(props) => props.theme.colorPrimary};
`;

const PrimaryText = ({ children }) => <StyledText>{children}</StyledText>;

export default PrimaryText;
