import { GET_QUESTIONS, CLEAR_QUESTIONS } from "./actions";

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_QUESTIONS:
      return {
        ...state,
        ...payload,
      };
    case CLEAR_QUESTIONS:
      return {
        ...payload,
      };
    default:
      return state;
  }
}
