import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardFooter,
  MatTextbox,
  FileInput,
  FadeIn,
  PrimaryButton,
  SecondaryButton,
} from "../../../shared";
import {
  setFormField,
  getPlayer,
  clearPlayer,
  addPlayer,
  editPlayer,
  uploadPlayerImage,
} from "../actions";
import { getPlayerName } from "utils";
import avatar from "assets/images/avatar.png";

const AddPlayer = ({
  activePlayer,
  getPlayer,
  clearPlayer,
  editPlayer,
  addPlayer,
  uploadPlayerImage,
  setFormField,
}) => {
  const history = useHistory();
  const params = useParams();
  const playerId = params.player;

  const [state, setState] = useState({
    loading: true,
  });

  const { loading } = state;

  useEffect(() => {
    if (playerId === "add-player") {
      setState({
        ...state,
        loading: false,
      });
    } else {
      setState({
        ...state,
        loading: false,
      });
      getPlayer(playerId);
    }

    return () => clearPlayer();
  }, [getPlayer, clearPlayer]);

  const handleTextboxChanged = (e) => {
    setFormField(e.target.name, e.target.value);
  };

  const handleSubmit = () => {
    if (playerId === "add-player") {
      addPlayer(activePlayer);
    } else {
      editPlayer(activePlayer);
    }
  };

  const handleCancel = () => {
    history.push("/team");
  };

  const handleImageUpload = (files) => {
    var reader = new FileReader();

    reader.onload = function (e) {
      uploadPlayerImage(playerId, e.target.result);
    };

    reader.readAsArrayBuffer(files[0]);
  };

  const isValid = () => {
    return activePlayer && activePlayer.name && activePlayer.name !== "";
  };

  return activePlayer && !loading ? (
    <FadeIn>
      <div className="row">
        <div className="col-md-8">
          <Card>
            <CardHeader
              cardTitle={
                playerId === "/team/add-player"
                  ? "Add New Player"
                  : "Player Card"
              }
            ></CardHeader>
            <div className="row">
              <div className="col-md-12">
                <MatTextbox
                isReadonly
                  onTextboxChanged={handleTextboxChanged}
                  name="firstName"
                  label="First Name"
                  value={activePlayer.firstName}
                />
              </div>
              <div className="col-md-12">
                <MatTextbox
                  onTextboxChanged={handleTextboxChanged}
                  name="lastName"
                  label="Last Name"
                  isReadonly
                  value={activePlayer.lastName}
                />
              </div>
              <div className="col-md-4">
                <MatTextbox
                  onTextboxChanged={handleTextboxChanged}
                  name="jerseyNum"
                  isReadonly
                  label="Shirt Number"
                  value={activePlayer.jerseyNum}
                />
              </div>
              <div className="col-md-8">
                <MatTextbox
                  isReadonly
                  onTextboxChanged={handleTextboxChanged}
                  name="realPosition"
                  label="Position"
                  value={activePlayer.realPosition}
                />
              </div>
            </div>
            {/* <CardFooter justifyContent="flex-end">
              <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
              <PrimaryButton onClick={handleSubmit} disabled={!isValid}>
                Save
              </PrimaryButton>
            </CardFooter> */}
          </Card>
        </div>
        <div className="col-md-4">
          <Card cardTitle="Image">
            <FileInput
              emptyImage={avatar}
              onChange={handleImageUpload}
              imageUrl={activePlayer.avatar}
              recommendedSize="265px x 400px"
            />
          </Card>
        </div>
      </div>
    </FadeIn>
  ) : null;
};

const mapStateToProps = (state) => ({
  activePlayer: state.team.activePlayer,
});

const mapActionsToProps = {
  setFormField,
  getPlayer,
  clearPlayer,
  addPlayer,
  editPlayer,
  uploadPlayerImage,
};

export default connect(mapStateToProps, mapActionsToProps)(AddPlayer);
