import React from "react";
import styled from "styled-components";
import {ReactComponent as LoopLogo} from 'LoopLogo2.svg'
const StyledWrapper = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 2px;
  fill: white;
  display: flex;
  align-items: center;
  justify-content: center;

  .looplogo{
height:40px;

  }
  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
 /*    flex: 0 0 170px; */
  }
`;

const TopbarLogo = (props) => <StyledWrapper><LoopLogo className="looplogo"/></StyledWrapper>;

export default TopbarLogo;
