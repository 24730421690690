import axios from "axios";

export const GET_USERS = "users:GET_USERS";

// const users = {
//   data: [
//     {
//       dateAdded: "Sat Apr 14 - 3:01pm",
//       name: "John Smith",
//       username: "jSmitty12",
//       age: "34",
//       promo: "Fiver02",
//       contests: "2",
//       correct: "43%",
//       winnings: "£45",
//     },
//     {
//       dateAdded: "Sat Apr 14 - 3:01pm",
//       name: "John Smith",
//       username: "jSmitty12",
//       age: "34",
//       promo: "Fiver02",
//       contests: "2",
//       correct: "43%",
//       winnings: "£45",
//     },
//     {
//       dateAdded: "Sat Apr 14 - 3:01pm",
//       name: "John Smith",
//       username: "jSmitty12",
//       age: "34",
//       promo: "Fiver02",
//       contests: "2",
//       correct: "43%",
//       winnings: "£45",
//     },
//     {
//       dateAdded: "Sat Apr 14 - 3:01pm",
//       name: "John Smith",
//       username: "jSmitty12",
//       age: "34",
//       promo: "Fiver02",
//       contests: "2",
//       correct: "43%",
//       winnings: "£45",
//     },
//   ],
// };

export const getUsers = () => (dispatch) => {
  axios.get(`/users`).then((res) => {
    dispatch({
      type: GET_USERS,
      payload: res.data.data,
    });
  });
  // dispatch({
  //   type: GET_USERS,
  //   payload: users,
  // });
};
