import React from "react";
import styled from "styled-components";

const StyledRow = styled.div`
  margin-bottom: 1rem;
`;

const FormRow = ({ children }) => <StyledRow>{children}</StyledRow>;

export default FormRow;
