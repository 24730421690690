import React from "react";
import styled from "styled-components";
import { Tooltip } from "react-tippy";

const StyledChip = styled.span`
  display: inline-flex;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.75rem 1.25rem;
  color: ${(props) => props.theme.colorText};
  user-select: none;

  ${(props) => props.status === "APPROVED" && `background: #e1f5eb`};

  ${(props) => props.status === "DRAFT" && `background: #e8f2ff`};
`;

const StyledBadge = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;

  ${(props) =>
    props.status === "APPROVED" && `background: ${props.theme.colorSuccess}`};

  ${(props) =>
    props.status === "DRAFT" && `background: ${props.theme.colorPrimary}`};
`;

const StyledChipText = styled.span`
  margin-left: 0.75rem;
  margin-right: 0.25rem;
  font-size: 0.875rem;
`;

const Chip = ({ children, tooltip }) => {
  const friendlyStatus =
    children === "DRAFT"
      ? "Draft"
      : children === "APPROVED"
      ? "Published"
      : "Draft";

  return (
    <Tooltip disabled={!tooltip} title={tooltip} position="top" delay={300}>
      <StyledChip status={children}>
        <StyledBadge status={children} />
        <StyledChipText>{friendlyStatus}</StyledChipText>
      </StyledChip>
    </Tooltip>
  );
};

export default Chip;
