import React from "react";
import styled from "styled-components";

const StyledTable = styled.table`
  width: 100%;
  min-width: ${(props) => props.minWidth || "auto"};
  border-collapse: collapse;

  ${(props) =>
    props.cellVerticalAlign
      ? `
    & td {
      vertical-align: top;
    }
  `
      : ""};
`;

const StyledTbody = styled.tbody`
  & > tr {
    border-top: 2px solid ${(props) => props.theme.colorBorder};
  }
`;

const StyledThead = styled.thead`
  ${(props) => props.isSortable && `& th { padding: 0 }`};
`;

const StyledTr = styled.tr``;

const StyledTd = styled.td`
  padding: 1rem 1rem 1rem 0;
  font-size: 0.875rem;
  color: ${(props) => props.theme.colorText};
`;

const StyledTh = styled.th`
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 1rem 0;
  color: ${(props) => props.theme.colorLabel};
`;

const Table = (props) => <StyledTable {...props}>{props.children}</StyledTable>;
const Tbody = (props) => <StyledTbody>{props.children}</StyledTbody>;
const Thead = (props) => <StyledThead {...props}>{props.children}</StyledThead>;
const Tr = (props) => <StyledTr {...props}>{props.children}</StyledTr>;
const Td = (props) => (
  <StyledTd
    style={{ verticalAlign: props.verticalAlign || "center" }}
    {...props}
  >
    {props.children}
  </StyledTd>
);
const Th = (props) => <StyledTh {...props}>{props.children}</StyledTh>;

export { Table, Tbody, Thead, Tr, Td, Th };
