import React from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: ${(props) => (props.noFlex ? "block" : "flex")};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    padding: 0 1rem;
  }

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    width: 900px;
    margin: 0 auto;
    padding: 0;
  }

  @media (min-width: ${(props) => props.theme.screenWidthLg}) {
    width: 1080px;
    margin: 0 auto;
  }
`;

const Container = (props) => (
  <StyledContainer noFlex={props.noFlex}>{props.children}</StyledContainer>
);

export default Container;
