import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getTeam, clearTeam, uploadTeamImage } from "./actions";
import { Card, CardHeader, FadeIn } from "../../shared";
import TeamPlayers from "./TeamPlayers";
import TeamInfo from "./TeamInfo";
import { getPlayerName } from "utils";
import { useSorting } from "components/hooks";

const Team = ({
  team,
  teamId,
  teamLogo,
  getTeam,
  clearTeam,
  uploadTeamImage,
}) => {
  useEffect(() => {
    getTeam();
    return () => clearTeam();
  }, [getTeam, clearTeam]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleImageUpload = (files) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      uploadTeamImage(teamId, e.target.result);
    };
    reader.readAsArrayBuffer(files[0]);
  };

  const searchChanged = (e) => {
    setSearchTerm(e.target.value);
  };

  const [sortField, sortDirection, sortButtonClicked, sortedItems] = useSorting(
    "lastName",
    "asc",
    team && team.teamPlayers
  );

  const filteredItems = !sortedItems
    ? undefined
    : sortedItems.filter(
        (obj) =>
          searchTerm === "" ||
          obj.lastName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          obj.firstName.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          getPlayerName(obj).toLowerCase().indexOf(searchTerm.toLowerCase()) >
            -1
      );

  return team && team.teamPlayers && filteredItems ? (
    <FadeIn>
      <div className="row">
        <div className="col-md-8">
          <Card>
            <CardHeader cardTitle="Players" marginBottom="0" />
            <TeamPlayers
              data={filteredItems}
              searchChanged={searchChanged}
              sortField={sortField}
              sortDirection={sortDirection}
              sortButtonClicked={sortButtonClicked}
              searchTerm={searchTerm}
            />
          </Card>
        </div>
        <div className="col-md-4">
          <Card>
            <CardHeader cardTitle="Logo" />
            <TeamInfo
              handleImageUpload={handleImageUpload}
              teamLogo={teamLogo}
            />
          </Card>
        </div>
      </div>
    </FadeIn>
  ) : null;
};

const mapStateToProps = (state) => ({
  team: state.team,
  teamId: state.auth.teamId,
  teamLogo: state.auth.teamLogo,
});

const mapActionsToProps = {
  getTeam,
  clearTeam,
  uploadTeamImage,
};

export default connect(mapStateToProps, mapActionsToProps)(Team);
