import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const StyledLogo = styled.img`
  height: 56px;

  @media (min-width: ${(props) => props.theme.screenWidthXs}) {
    margin-right: 2rem;
  }

  @media (min-width: ${(props) => props.theme.screenWidthMd}) {
    height: 80px;
  }
`;

const NavbarLogo = (props) => {
  return props.auth.teamLogo ? <StyledLogo src={props.auth.teamLogo} /> : null;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(NavbarLogo);
