import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { setActiveModal } from "./actions";

const StyledOverlay = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  background: rgba(0, 0, 0, 0.35);
  transition-property: visibility, opacity;
  transition-duration: 0.25s;
`;

const Overlay = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.overlay.isOpen);
  return (
    <StyledOverlay
      isOpen={isOpen}
      onClick={() => dispatch(setActiveModal(undefined))}
    />
  );
};

export default Overlay;
