import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Container } from "../../../shared";
import TopbarLogo from "./TopbarLogo";
import TopbarButtons from "./TopbarButtons";
import TopbarNav from "./TopbarNav";
import { logout } from "../../../feature/auth/actions";

const StyledTopbar = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
`;

const Topbar = ({ auth, logout, isScrolled }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setDropdownOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <StyledTopbar
      style={{
        background: auth.colorTeamBackground,
        color: auth.colorTeamText,
      }}
    >
      <Container>
        <TopbarLogo />
        {isScrolled && <TopbarNav colorTeamText={auth.colorTeamText} />}
        <TopbarButtons
          userFullName={auth.userFullName}
          logout={logout}
          handleClick={() => setDropdownOpen(true)}
          dropdownOpen={dropdownOpen}
        />
      </Container>
    </StyledTopbar>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapActionsToProps = {
  logout,
};

export default connect(mapStateToProps, mapActionsToProps)(Topbar);
