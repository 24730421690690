import axios from "axios";
import { handleError } from "utils";

export const GET_DASHBOARD = "dashboard:GET_DASHBOARD";
export const CLEAR_DASHBOARD = "dashboard:CLEAR_DASHBOARD";

export function clearDashboard() {
  return {
    type: CLEAR_DASHBOARD,
    payload: {},
  };
}

export const getDashboard = () => (dispatch) => {
  axios
    .get(`/tenant/stats`)
    .then((res) => {
      const { data } = res;
      const bars = createBars(data.data.usersAgeRange, data.data.usersCount);
      const stats = createStats(data.data);

      dispatch({
        type: GET_DASHBOARD,
        payload: {
          stats,
          bars,
          lineChart: data.data.pickemBetsPerPickem,
        },
      });
    })
    .catch(handleError);
};

const percentage = (value, total) => ((value / total) * 100).toFixed(0);

const createBars = (data, totalUsers) => {
  let bars = [
    {
      title: "Users Age Range",
      bars: [
        {
          title: "18-24",
          percentage: percentage(data["18-24"], totalUsers),
        },
        {
          title: "25-34",
          percentage: percentage(data["25-34"], totalUsers),
        },
        {
          title: "35-44",
          percentage: percentage(data["35-44"], totalUsers),
        },
        {
          title: "45-54",
          percentage: percentage(data["45-54"], totalUsers),
        },
        {
          title: "55+",
          percentage: percentage(data["55+"], totalUsers),
        },
      ],
      // footer: {
      //   title: "Avg. Age of all users",
      //   value: users.ageRange.average,
      // },
    },
  ];

  return bars;
};

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const createStats = (data) => {
  return [
    {
      title: "Total Users",
      value: data.usersCount,
    },
    {
      title: "Active Users",
      value: data.activeUsersCount,
    },
    {
      title: "Entries",
      value: data.pickemBetsCount,
    },
    {
      title: "Prize Pool",
      value: `£${numberWithCommas(data.prizePoolTotal / 100)}`,
    },
    {
      title: "Contests Entered Per User",
      value: data.userPickemsAvg.toFixed(2),
    },
    {
      title: "% of money winning users",
      value: `${data.winningUsersPercentage}%`,
    },
  ];
};
