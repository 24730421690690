import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% { transform: translateX(-100%) translateY(-50%) rotate(15deg);  }
  100% { transform: translateX(100%) translateY(-50%) rotate(15deg); }
`;

const StyledSkeleton = styled.span`
  display: ${(props) => props.display};
  position: relative;
  overflow: hidden;
  border-radius: ${(props) =>
    props.isCircle ? "50%" : props.skeletonBorderRadius};
  background: ${(props) =>
    props.isDarker ? props.theme.colorSkeleton : props.theme.colorLightGrey};
  width: ${(props) => props.skeletonWidth};
  height: ${(props) => props.skeletonHeight};
  margin: ${(props) => props.margin};

  &::after {
    content: "";
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 0;
    width: 260px;
    height: 1200px;
    background: #e2e5e8;
    animation: ${shimmer} 1500ms linear infinite;
  }
`;

const Skeleton = ({
  className,
  skeletonWidth,
  skeletonHeight,
  skeletonBorderRadius,
  isCircle,
  isDarker,
  margin,
  display,
}) => (
  <StyledSkeleton
    className={className}
    skeletonWidth={skeletonWidth}
    skeletonHeight={skeletonHeight}
    skeletonBorderRadius={skeletonBorderRadius}
    isCircle={isCircle}
    isDarker={isDarker}
    margin={margin}
    display={display}
  />
);

Skeleton.defaultProps = {
  skeletonWidth: "100px",
  skeletonHeight: "10px",
  skeletonBorderRadius: "0.5rem",
  isCircle: false,
  isDarker: false,
  margin: "0",
  display: "inline-block",
};

Skeleton.propTypes = {
  skeletonWidth: PropTypes.string,
  skeletonHeight: PropTypes.string,
  skeletonBorderRadius: PropTypes.string,
  isCircle: PropTypes.bool,
  isDarker: PropTypes.bool,
  margin: PropTypes.string,
  display: PropTypes.string,
};

export default Skeleton;
