import { combineReducers } from "redux";
import authReducer from "../components/feature/auth/reducer";
import dashboardReducer from "../components/feature/dashboard/reducer";
import teamReducer from "../components/feature/team/reducer";
import contestReducer from "../components/feature/contest/reducer";
import fixtureTableReducer from "../components/feature/fixtureTable/reducer";
import leaderboardReducer from "../components/feature/leaderboard/reducer";
import questionsReducer from "../components/feature/questions/reducer";
import usersReducer from "../components/feature/users/reducer";

import overlayReducer from "components/layout/overlay/reducer";

export default combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  contest: contestReducer,
  fixtureTable: fixtureTableReducer,
  team: teamReducer,
  leaderboard: leaderboardReducer,
  questions: questionsReducer,
  users: usersReducer,
  overlay: overlayReducer,
});
