import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getQuestions, clearQuestions } from "./actions";
import {
  Card,
  CardHeader,
  IconButton,
  LinkButton,
  FadeIn,
  SkeletonTable,
  LabelText,
  TableSortButton,
} from "components/shared";
import { Table, Thead, Tbody, Tr, Td, Th } from "components/shared/table/Table";
import AddQuestions from "./AddQuestions";
import { useSorting } from "components/hooks";

const Questions = ({
  getQuestions,
  clearQuestions,
  addQuestion,
  questionTemplates,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getQuestions();
    return () => clearQuestions();
  }, [getQuestions, clearQuestions]);

  const [sortField, sortDirection, sortButtonClicked, sortedItems] = useSorting(
    "title",
    "asc",
    questionTemplates
  );

  const toggleModalOpen = () => {
    this.setModalOpen(!modalOpen);
  };

  return !sortedItems ? (
    <SkeletonTable />
  ) : (
    <Card>
      <CardHeader cardTitle="Questions" />
      <FadeIn>
        <AddQuestions
          isOpen={modalOpen}
          onClose={toggleModalOpen}
          onSave={addQuestion}
        />
        <Table>
          <Thead isSortable>
            <Tr>
              {/* <Th>#</Th> */}
              <Th style={{ padding: 0 }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="title"
                  sortDirection={sortDirection}
                  isSorted={sortField === "title"}
                >
                  Question
                </TableSortButton>
              </Th>
              <Th style={{ padding: 0 }}>
                <TableSortButton
                  onClick={sortButtonClicked}
                  sortField="answer_options"
                  sortDirection={sortDirection}
                  isSorted={sortField === "answer_options"}
                >
                  Options
                </TableSortButton>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedItems.map((question, index) => (
              <Tr key={question.id}>
                {/* <Td>{index + 1}</Td> */}
                <Td>{question.formattedTitle}</Td>
                <Td>
                  <LabelText style={{ marginRight: "0.5rem" }}>
                    Outcome:
                  </LabelText>{" "}
                  {question.formattedOptions}
                </Td>
                {/* <Td>
                  <LinkButton to="/fixtures">Edit</LinkButton>
                  <IconButton
                    onClick={() => this.props.deleteQuestion(index)}
                    tooltip="Delete"
                  >
                    delete
                  </IconButton>
                </Td> */}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </FadeIn>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  questionTemplates: state.questions.questionTemplates,
});

const mapActionsToProps = {
  getQuestions,
  clearQuestions,
};

export default connect(mapStateToProps, mapActionsToProps)(Questions);
