import {
  CLEAR_CONTEST,
  GET_CONTEST,
  UPDATE_CONTEST,
  UPDATE_QUESTIONS,
  CLEAR_QUESTIONS,
  DELETE_QUESTION,
  SET_FORM_FIELD,
  SET_SAVING_CONTEST,
  SET_SAVING_QUESTIONS,
  SET_DELETING_QUESTION,
  SET_QUESTIONS_FOR_DELETE,
  SET_QUESTION_ORDER_LIST,
} from "./actions";

const initialState = {
  activeContest: undefined,
  contestName: undefined,
  contestStake: 0,
  questions: undefined,
  isSavingContest: undefined,
  isSavingQuestions: undefined,
  isDeletingQuestion: undefined,
  questionsForDelete: [],
  questionOrderList: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_QUESTIONS_FOR_DELETE:
      return {
        ...state,
        questionsForDelete: payload,
      };
    case SET_QUESTION_ORDER_LIST:
      return {
        ...state,
        questionOrderList: payload,
      };
    case CLEAR_CONTEST:
      return {
        ...state,
        activeContest: {},
        questions: undefined,
        questionsForDelete: [],
      };
    case GET_CONTEST:
      return {
        ...state,
        activeContest: payload.contest,
        questions: payload.contest.questions,
        questionOrderList: payload.contest.questions.map((obj) => ({
          id: obj.id,
          label: obj.label,
        })),
        statusList: payload.statusList,
        questionsForDelete: [],
      };
    case UPDATE_QUESTIONS:
      return {
        ...state,
      };
    case CLEAR_QUESTIONS:
      return {
        ...state,
        data: payload,
      };
    case UPDATE_CONTEST:
      return {
        ...state,
      };
    case DELETE_QUESTION:
      return {
        ...state,
        question: payload.data,
      };
    case SET_FORM_FIELD:
      return {
        ...state,
        activeContest: {
          ...state.activeContest,
          ...payload,
        },
      };
    case SET_SAVING_CONTEST:
      return {
        ...state,
        isSavingContest: payload,
      };
    case SET_SAVING_QUESTIONS:
      return {
        ...state,
        isSavingQuestions: payload,
      };
    case SET_DELETING_QUESTION:
      return {
        ...state,
        isDeletingQuestion: payload,
      };
    default:
      return state;
  }
}
