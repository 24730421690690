import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { MatIcon, Spinner } from "../";

const sharedStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: ${(props) =>
    props.isLoading ? props.theme.colorPrimary : props.theme.colorLightGrey};
  color: #979ea7;

  &:hover {
    background: ${(props) => props.theme.colorPrimary};
    color: white;
  }
`;

const StyledButton = styled.button`
  ${sharedStyles};
`;

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${sharedStyles};
`;

const IconButton = (props) => {
  const { isLoading, tooltip, to, children } = props;

  return (
    <Tooltip
      hideOnClick={false}
      title={tooltip}
      position="top"
      delay={500}
      distance={16}
    >
      {to ? (
        <StyledLink {...props}>
          <MatIcon size="16px">{children}</MatIcon>
        </StyledLink>
      ) : (
        <StyledButton {...props}>
          {!isLoading && <MatIcon size="16px">{children}</MatIcon>}
          {isLoading && <Spinner lineWidth={3} forDark />}
        </StyledButton>
      )}
    </Tooltip>
  );
};

IconButton.propTypes = {
  to: PropTypes.string,
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default IconButton;
