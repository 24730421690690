import React from "react";
import styled from "styled-components";
import { Card, CardHeader } from "components/shared";

const StyledValue = styled.div`
  font-size: 36px;
  font-weight: 500;
  color: ${(props) => props.theme.colorText};
`;

const StyledCardInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const StyledDescription = styled.div`
  margin: 0 1rem;
  font-size: 0.75rem;
  line-height: 1.25;
  text-align: right;
  color: ${(props) => props.theme.colorLabel};
`;

const DashboardStatCard = ({ cardTitle, cardDescription, cardValue }) => (
  <Card cardTitle={cardTitle}>
    <CardHeader cardTitle={cardTitle}></CardHeader>
    <StyledCardInner>
      <StyledValue>{cardValue}</StyledValue>
      {cardDescription && (
        <StyledDescription>{cardDescription}</StyledDescription>
      )}
    </StyledCardInner>
  </Card>
);

export default DashboardStatCard;
