import axios from "axios";
import { toast } from "react-toastify";
import { handleError } from "../../../utils";
import history from "../../../router/history";

export const CLEAR_CONTEST = "contest:CLEAR_CONTEST";
export const GET_CONTEST = "contest:GET_CONTEST";
export const UPDATE_CONTEST = "contest:UPDATE_CONTEST";
export const UPDATE_QUESTIONS = "contest:UPDATE_QUESTIONS";
export const UPDATE_QUESTION_ORDER = "contest:UPDATE_QUESTION_ORDER";
export const CLEAR_QUESTIONS = "contest:CLEAR_QUESTIONS";
export const DELETE_QUESTION = "contest:DELETE_QUESTION";
export const SET_FORM_FIELD = "contest:SET_FORM_FIELD";
export const SET_SAVING_CONTEST = "contest:SET_SAVING_CONTEST";
export const SET_SAVING_QUESTIONS = "contest:SET_SAVING_QUESTIONS";
export const SET_DELETING_QUESTION = "contest:SET_DELETING_QUESTION";
export const SET_QUESTIONS_FOR_DELETE = "contest:SET_QUESTIONS_FOR_DELETE";
export const SET_QUESTION_ORDER_LIST = "contest:SET_QUESTION_ORDER_LIST";

export function setQuestionsForDelete(questions) {
  return {
    type: SET_QUESTIONS_FOR_DELETE,
    payload: questions,
  };
}

export const updateQuestionOrder = (contestId, questions) => (dispatch) => {
  axios
    .post(`/pickem-questions/${contestId}/order`, {
      questions: questions.map((obj) => obj.id),
    })
    .then((res) => {
      toast.success("Question Order Updated");
      dispatch(getContest(contestId));
    })
    .catch(handleError);
};

export function setQuestionOrderList(questions) {
  return {
    type: SET_QUESTION_ORDER_LIST,
    payload: questions,
  };
}

export function setSavingContest(val) {
  return {
    type: SET_SAVING_CONTEST,
    payload: val,
  };
}

export function setSavingQuestions(val) {
  return {
    type: SET_SAVING_QUESTIONS,
    payload: val,
  };
}

export function setDeletingQuestion(val) {
  return {
    type: SET_DELETING_QUESTION,
    payload: val,
  };
}

export function clearContest() {
  return {
    type: CLEAR_CONTEST,
    payload: undefined,
  };
}

export const getContest = (contestId) => (dispatch) => {
  axios
    .get(`/pickems/${contestId}`)
    .then(async (contestRes) => {
      await axios
        .get("/selects/pickem-status")
        .then((selectsRes) => {
          dispatch({
            type: GET_CONTEST,
            payload: {
              contest: contestRes.data.data,
              statusList: selectsRes.data.statuses,
            },
          });
        })
        .catch(handleError);
    })
    .catch(handleError);
};

export const createContest = (fixtureId, params) => (dispatch) => {
  dispatch(setSavingContest(true));
  axios
    .post(`/pickems`, params)
    .then(({ data }) => {
      dispatch(setSavingContest(false));
      toast.success(`Contest Created`);
      history.push(`/fixtures/${fixtureId}/${data.data.id}`);
    })
    .catch(handleError);
};

export const updateContest = (params, isPublish) => (dispatch) => {
  dispatch(setSavingContest(true));

  if (isPublish) {
    params.status = "APPROVED";
  }

  axios
    .put(`/pickems/${params.id}`, params)
    .then((res) => {
      toast.success(`Contest Updated`);
      dispatch(getContest(params.id));
      dispatch(setSavingContest(false));
      dispatch(setQuestionsForDelete([]));
      dispatch({
        type: UPDATE_CONTEST,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const clearQuestions = () => (dispatch) => {
  dispatch({
    type: CLEAR_QUESTIONS,
    payload: undefined,
  });
};

export function setFormField(name, value, type) {
  return {
    type: SET_FORM_FIELD,
    payload: {
      [name]: type === "number" ? +value : value,
    },
  };
}

export const deleteQuestion = (contestId, contestQuestionId) => (dispatch) => {
  dispatch(setDeletingQuestion(contestQuestionId));
  axios
    .delete(`/pickem-questions/${contestQuestionId}`)
    .then((res) => {
      toast.success(`Question Deleted`);
      dispatch(setDeletingQuestion(undefined));
      dispatch(getContest(contestId));
    })
    .catch(({ response }) => {
      dispatch(setDeletingQuestion(undefined));
      handleError(response);
    });
};

export const updateQuestions = (fixtureId, questions) => (dispatch) => {
  dispatch({
    type: UPDATE_QUESTIONS,
    payload: questions,
  });
};

const createQuestion = (item) => {
  return axios.post(`/pickem-questions`, item);
};

export const saveContestQuestions = (
  contestId,
  questions,
  questionsForDelete
) => async (dispatch) => {
  dispatch(setSavingQuestions(true));

  try {
    if (questionsForDelete && questionsForDelete.length > 0) {
      const apiDeleteRequests = questionsForDelete.map((item, i) =>
        axios.delete(`/pickem-questions/${item}`)
      );

      Promise.all(apiDeleteRequests).then(() => {
        toast.success(`Questions Deleted`);
        dispatch(getContest(contestId));
        dispatch(setSavingQuestions(false));
      });
    }

    const orderList = questions.map((obj) => obj.order || 0);
    const highestOrder = Math.max(...orderList);
    const newItems = questions.filter((q) => q.id === -1);

    if (newItems && newItems.length > 0) {
      const apiPostRequests = newItems.map((item, i) => {
        item.order = highestOrder + i + 1;
        item.id = undefined;
        return axios.post(`/pickem-questions`, item);
      });

      Promise.all(apiPostRequests).then(() => {
        toast.success(`Questions Updated`);
        //setTimeout(() => {
        dispatch(getContest(contestId));
        dispatch(setSavingQuestions(false));
        //}, 500);
      });
    }
  } catch (error) {
    dispatch(setSavingQuestions(false));
    handleError(error);
  }
};
