import React from "react";
import styled from "styled-components";
import { FileInput } from "../../shared";

const TeamInfo = ({ teamLogo, handleImageUpload }) => (
  <div>
    <FileInput imageUrl={teamLogo} onChange={handleImageUpload} />
  </div>
);

export default TeamInfo;
