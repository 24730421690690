import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";

const forTableStyling = css`
  padding: 0.5rem 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  height: 40px;
  color: ${(props) => props.theme.colorText};
`;

const StyledSelect = styled.select`
  padding: 0.325rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  border: 2px solid ${(props) => props.theme.colorBorder};
  color: ${(props) => props.theme.colorLabel};

  ${(props) => props.stretch && "width: 100%"};
  ${(props) => props.forTable && forTableStyling};
`;

const Dropdown = ({
  onChange,
  stretch,
  forTable,
  className,
  tooltip,
  options,
}) => {
  return (
    <Tooltip
      enabled={tooltip}
      title={tooltip}
      position="top"
      delay={300}
      distance={16}
    >
      <StyledSelect
        onChange={onChange}
        stretch={stretch}
        forTable={forTable}
        className={className}
      >
        {options.map((obj) => (
          <option key={obj.key} value={obj.value}>
            {obj.text}
          </option>
        ))}
      </StyledSelect>
    </Tooltip>
  );
};

Dropdown.defaultProps = {
  options: [{ text: "Please select", value: -1 }],
};

Dropdown.propTypes = {
  stretch: PropTypes.bool,
  forTableStyling: PropTypes.bool,
};

export default Dropdown;
