import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ReactSortable } from "react-sortablejs";
import { Modal, CardFooter, BlockButton } from "components/shared";
import { setActiveModal } from "components/layout/overlay/actions";
import { setQuestionOrderList, updateQuestionOrder } from "./actions";

const StyledList = styled(ReactSortable)`
  margin-bottom: 1rem;
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  border-bottom: 2px solid ${(props) => props.theme.colorBorder};
  cursor: move;
`;

const ContestSortModal = ({ onConfirm }) => {
  const activeContest = useSelector((state) => state.contest.activeContest);
  const questionOrderList = useSelector(
    (state) => state.contest.questionOrderList
  );
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.overlay.activeModal);

  const setList = (e) => {
    dispatch(
      setQuestionOrderList(e.map((obj) => ({ id: obj.id, label: obj.label })))
    );
  };

  const cancel = () => {
    dispatch(setActiveModal(false));
    setList(
      activeContest.questions.map((obj) => ({ id: obj.id, label: obj.label }))
    );
  };

  const save = () => {
    dispatch(setActiveModal(false));
    dispatch(updateQuestionOrder(activeContest.id, questionOrderList));
  };

  useEffect(() => {
    if (activeContest && questionOrderList === undefined) {
      setList(
        activeContest.questions.map((obj) => ({ id: obj.id, label: obj.label }))
      );
    }
  }, [activeContest, questionOrderList, setList]);

  const list = questionOrderList;

  return (
    <Modal
      idealWidth="700px"
      title="Edit Question Order"
      isOpen={activeModal === "sort-questions"}
    >
      <StyledList list={list} setList={setList}>
        {list.map((q, i) => (
          <StyledRow key={i}>{q.label}</StyledRow>
        ))}
      </StyledList>
      <CardFooter justifyContent="flex-end">
        <BlockButton onClick={cancel}>Cancel</BlockButton>
        <BlockButton isPrimary onClick={save}>
          Save
        </BlockButton>
      </CardFooter>
    </Modal>
  );
};

export default ContestSortModal;
