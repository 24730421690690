import {
  SET_FORM_FIELD,
  GET_TEAM,
  CLEAR_TEAM,
  GET_PLAYER,
  CLEAR_PLAYER,
  ADD_PLAYER,
  EDIT_PLAYER,
  UPLOAD_PLAYER_IMAGE,
} from "./actions";

const initialState = {
  teamPlayers: undefined,
  activePlayer: undefined,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_FORM_FIELD:
      return {
        ...state,
        activePlayer: {
          ...state.activePlayer,
          ...payload,
        },
      };
    case GET_TEAM:
      return {
        ...state,
        teamPlayers: payload,
      };
    case CLEAR_TEAM:
      return {
        ...state,
        teamPlayers: payload,
      };
    case GET_PLAYER:
      return {
        ...state,
        activePlayer: payload,
      };
    case CLEAR_PLAYER:
      return {
        ...state,
        activePlayer: payload,
      };
    case ADD_PLAYER:
      return {
        ...state,
        activePlayer: payload,
      };
    case EDIT_PLAYER:
      return {
        ...state,
        activePlayer: payload,
      };
    case UPLOAD_PLAYER_IMAGE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
