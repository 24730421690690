import history from "../router/history";
import { toast } from "react-toastify";

export const getPlayerName = (player) => {
  return player.name || `${player.firstName} ${player.lastName}`;
};

export function handleError(error) {
  console.log("error", error);

  if (!error && !error.response) {
    toast.error("Error: No Response");
    return;
  }

  const { response } = error;

  let friendlyMessage = "Error: No Response Data";
  if (response.data && response.data.message) {
    const { message, exception } = response.data;
    if (
      message.indexOf("Token has expired") > -1 ||
      exception.indexOf("Unauthorized") > -1
    ) {
      friendlyMessage = "Access Denied: Please Login";
    } else {
      friendlyMessage = message;
    }
  }

  toast.error(friendlyMessage);

  if (response) {
    const { status } = response;

    if (status === 401) {
      history.push("/");
    }
  }
}
