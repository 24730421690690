import { CHECK_LOGIN, LOAD_FROM_LOCAL_STORAGE } from "./actions";

const initialState = {};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case CHECK_LOGIN:
      return {
        ...state,
        ...payload,
      };
    case LOAD_FROM_LOCAL_STORAGE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
