export const theme = {
  colorPrimary: "#3283e9",
  colorText: "#626a75",
  colorBorder: "#e2e7ec",
  colorLightGrey: "#eef0f3",
  colorSuccess: "#3bbe80",
  colorDanger: "red",
  colorLabel: "#aab2bd",
  colorLabelHover: "#909aa8",
  colorPlaceholder: "#b7c0ce",
  colorDisabledBackground: "#c8ccd1",
  colorDisabledText: "#a4acb6",
  colorSpinnerBackground: "#eef0f3",
  colorSpinnerBackgroundForDark: "rgba(255, 255, 255, 0.15)",
  boxShadowCard: "0px 0px 30px 0px rgba(82, 63, 105, 0.05)",
  fontFamily: "Poppins",
  screenWidthXs: "576px",
  screenWidthSm: "768px",
  screenWidthMd: "992px",
  screenWidthLg: "1200px",
  screenWidthXl: "1920px",
  screenWidthXxl: "2400px",
};
